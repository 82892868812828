// src/lib/redux/bookOffersById/ApiActionCreator.js
import { fetchData, fetchSuccess, fetchError } from './ApiAction';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

const fetchBookOffersById = (id) => async dispatch => {
  dispatch(fetchData());

  new Promise((resolve, reject) => {
    axios
      .get(`/api/book-offers/book-by-id`, { 
        params: { id } 
      })
      .then((response) => {
        if (response.data) {
          dispatch(fetchSuccess(response.data));
        }
        resolve(response);
      })
      .catch((error) => {
        console.error('Błąd w fetchBookOffersById: ', error);
        dispatch(fetchError(error));
        reject(error);
      });
  });
};

export default fetchBookOffersById;
