import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { FaTruck } from 'react-icons/fa';
import PropTypes from 'prop-types';

import styles from './css/delivery-options-modal-box.module.css';

import { Trans } from '@/utils/intlTranslations';
import { renderStatusIcon } from '@/utils';

export const DeliveryOptionsModalBox = ({ modalTitle, location, openModal, setOpenModal }) => {
    // const [openModal, setOpenModal] = useState(false);

    // Przygotowanie danych dla opcji dostaw
    const deliveryOptions = [
        { name: "pickup_only", value: location.pickup_only},
        { name: "no_pickup", value: location.no_pickup},
        { name: "poczta_polska", value: location.poczta_polska},
        { name: "gls", value: location.gls},
        { name: "dhl", value: location.dhl},
        { name: "orlen_paczka", value: location.orlen_paczka},
        { name: "inpost", value: location.inpost},
        { name: "cash_on_delivery", value: location.cash_on_delivery},
    ];

    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-title"
            PaperProps={{
                style: {
                  maxHeight: '80vh',
                  marginBottom: '20vh', // Dostosuj wartość zgodnie z wysokością twojego menu
                },
            }}
        >
            <DialogTitle id="modal-title"><FaTruck className={styles["delivery-options--ico"]}/>{modalTitle}</DialogTitle>
            <DialogContent style={{ paddingBottom: '0px'}}>
                <div className={styles["delivery-options--sec"]}>
                        {deliveryOptions.map((item, index) => (
                            <div key={index}>
                                <div className={styles["delivery-options--sec-item"]}>
                                    <div>
                                        <FormattedMessage
                                            id={item.name}
                                            defaultMessage={Trans(item.name)}
                                        />
                                        {renderStatusIcon(item.value)}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <hr />
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {/* pickup_only */}
                    {/* <div className={styles["delivery-options--sec-checkbox"]}>
                        <div className={styles["delivery-options--sec-checkbox-d"]}>
                            <span style={{marginRight: '-6px', color: '#40466a'}}>
                                <FormattedMessage
                                    id = "pickup_only"
                                    defaultMessage="Tylko odbiór osobisty"
                                />
                            </span>
                            {renderStatusIcon(location.pickup_only)}
                        </div>
                    </div> */}

                    {/* no_pickup */}
                    {/* <div className={styles["delivery-options--sec-checkbox"]}>
                        <div className={styles["delivery-options--sec-checkbox-d"]}>
                            <span style={{marginRight: '-6px', color: '#40466a'}}>
                                <FormattedMessage
                                    id = "no_pickup"
                                    defaultMessage="Brak możliwości odbioru osobistego"
                                />
                            </span>
                            {renderStatusIcon(location.no_pickup)}
                        </div>
                    </div> */}
                </div>
                {/* <hr /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Zamknij</Button>
            </DialogActions>
        </Dialog>
    );
}

// PropTypes
DeliveryOptionsModalBox.propTypes = {
    modalTitle: PropTypes.string,
    location: PropTypes.object,
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
  // Definicje dla pozostałych właściwości...
};