// src/components/AboutUs/index.js
import React from "react";
import { FormattedMessage } from 'react-intl';

import styles from './css/about-us.module.css';

export const AboutUs = () =>  (
  <div className={styles["ab--u-f"]}>
    <div className={styles["ab--u-h"]}>
      <FormattedMessage
        id = "AboutUs"
        defaultMessage="Kilka słów o nas"
      />
    </div>
    <div className={styles["ab--us-t"]}>
        <FormattedMessage
          id = "AboutUsDescription"
          values={{
            b: (chunks) => <strong>{chunks}</strong>
          }}
        />
    </div>
  </div>
)

// key={Math.random().toString()}
