// src/components/StarterPage/index.js
import React, { useEffect, useState } from "react";
// import { useRouter } from 'next/router';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';

import { FindYourBook } from '@/components/FindYourBook';
import { AboutUs } from '@/components/AboutUs';
import { PromoStarter } from '@/components/PromoStarter';
import { AddBookFooter } from '@/components/AddBookFooter';

import { YourResults } from '@/components/YourResults';
import { useBreadcrumbs } from '@/contexts/BreadcrumbsContext';
import { Breadcrumbs } from '@/components/_common/Breadcrumbs';
import { CardsGridTiles } from '@/components/CardsGridTiles';

const StarterPage = () => {
    const { setBreadcrumbs } = useBreadcrumbs();
    const router = useRouter();
    // Dostęp do bieżącej ścieżki
    const currentPathname = router.asPath;

    // Selektor Reduxa
    const { data: bookInformationData, loading:  bookInformationIsLoading } = useSelector((state) => state.bookInformation);;
    const { data: bookOffersData, loading:  bookOffersIsLoading, pagination:  bookOffersPagination} = useSelector((state) => state.bookOffers);

    // Runs on the first render
    // And any time any dependency value changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [currentPathname]);

    // Zaktualizuj breadcrumbs przy montowaniu komponentu
    useEffect(() => {
        setBreadcrumbs([
            { title: 'Home', href: '/' },
            // { title: 'title.........', href: '/' },
        ]);
        // To będzie wywołane tylko raz, gdy komponent zostanie zamontowany

        // Opcjonalnie zwróć funkcję czyszczącą, jeśli chcesz zresetować breadcrumbs
        // gdy komponent będzie odmontowywany
        return () => {
            setBreadcrumbs([]);
        };
    }, [setBreadcrumbs]);

    return (
            <>
                {/* StarterPage */}
                {/* <Breadcrumbs />  */}
                <FindYourBook />
                <CardsGridTiles 
                    cardsGridData={bookOffersData} 
                    searchPhrase={''}
                />
                <AboutUs />
                <PromoStarter />
                {/* <AddBookFooter /> */}
            </>
    );
}

export default StarterPage;


// import { useEffect } from 'react';
// import { useRouter } from 'next/router';

// function RedirectPage() {
//   const router = useRouter();

//   useEffect(() => {
//     router.replace('/StarterPage'); // przekieruj na stronę /StarterPage
//   }, []);

//   return null; // nie renderuj nic
// }

// export default RedirectPage;
