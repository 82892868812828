// src/components/CategoryBox/index.js
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import { Spinner } from 'react-bootstrap';
import { FaTh, FaListUl } from 'react-icons/fa'; //FaArrowsAltH, FaThList, FaThLarge
import PropTypes from 'prop-types';

import styles from './css/category-box.module.css';

import { useLanguage } from '@/contexts/LanguageContext';
import { useCategory } from '@/contexts/CategoryContext'
import { IconPaths } from '@/constants';
import { genAltText } from '@/utils';

import debugLog from '@/helpers/debugLog'; 

const CategoryBox = ({ categories, onCategoryClick, filterBuukiCategoryId = null, switchSubcatToCatFromCardsPlain }) => {
    const [expandedCategories, setExpandedCategories] = useState({});
    const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
    const [structuredCategories, setStructuredCategories] = useState([]); //wykorzystywane do renderCategories
    const {buukiCategoryIdsPath, updateBuukiCategoryIdsPath} = useCategory();
    const { locale } = useLanguage();

    // Logika do przekształcania danych kategorii na strukturę z podkategoriami
    // Wykorzystuje pola 'lft', 'rgt', i 'lvl' do stworzenia tej struktury
    const buildCategoryStructure = (rawCategories, categoryId = null) => {
        if (rawCategories?.length > 0) {      
            // Jeśli nie podano id kategorii, filtrujemy wszystkie kategorie, gdzie lvl === 0.
            let rootCategories = rawCategories.filter(c => c.lvl === 0);

            // Jeśli podano nazwę kategorii, filtrujemy tylko kategorie korzenia o tej nazwie.
            categoryId
            ? rootCategories = rawCategories.filter(c => c.lvl === 0 && c.id === categoryId)
            : '';
        
            const buildTree = (category, level) => {
            // const children = rawCategories.filter(c => c.lvl === level + 1 && c.root === category.id && c.lft > category.lft && c.rgt < category.rgt);
            const children = rawCategories.filter(c => c.lvl === level + 1 && c.root === category.root && c.lft > category.lft && c.rgt < category.rgt);
                return {
                    ...category,
                    subcategories: children.map(child => buildTree(child, level + 1)),
                };
            };

            // Jeśli podano id kategorii, zbuduj strukturę od pierwszego poziomu podkategorii dla tej kategorii root.
            if (categoryId) {
                const rootCategory = rawCategories.find(c => c.lvl === 0 && c.id === categoryId);
                // Jeśli kategoria root istnieje, zbuduj jej strukturę podkategorii.
                if (rootCategory) {
                    return buildTree(rootCategory, 0).subcategories;
                }
                return []; // Brak kategorii root o podanej nazwie
            } else {
                // Jeśli nie podano nazwy kategorii, filtrujemy wszystkie kategorie, gdzie lvl === 0 i budujemy strukturę dla nich.
                const rootCategories = rawCategories.filter(c => c.lvl === 0);
                return rootCategories.map(root => buildTree(root, 0));
            }
        }
        return [];
    };
    
    const findCategoryIdsPathInNestedSet = (categories, selectedCategoryId) => {
        let categoryIdsPath = [];
        let currentCategory = categories.find(category => category.id === selectedCategoryId);
        // debugLog('CategoryBox findCategoryIdsPathInNestedSet currentCategory', 'debug', currentCategory);
    
        while (currentCategory && currentCategory.lvl > 0) { // Używamy > zamiast !==, zakładając, że lvl korzenia to 0
            categoryIdsPath.unshift(currentCategory.id);
            // Znajdź rodzica
            currentCategory = categories.find(category => 
                currentCategory.lft > category.lft &&
                currentCategory.rgt < category.rgt &&
                category.lvl === currentCategory.lvl - 1 &&
                category.root === currentCategory.root
            );
        }
    
        // Jeśli wyszliśmy z pętli i currentCategory nie jest null, to musimy być w korzeniu
        if (currentCategory) {
            categoryIdsPath.unshift(currentCategory.id); // Dodajemy korzeń
        }
        
        return categoryIdsPath;
    };

    // Funkcja obsługująca kliknięcie na kategorię
    const handleCategoryClick = (category) => {
        let categoryIdsPath = [];
        categoryIdsPath = findCategoryIdsPathInNestedSet(categories, category.id);

        debugLog('CategoryBox handleCategoryClick updateBuukiCategoryIdsPath', 'debug', buukiCategoryIdsPath);
        debugLog('CategoryBox handleCategoryClick categoryIdsPath', 'debug', categoryIdsPath ? categoryIdsPath : []);

        updateBuukiCategoryIdsPath(categoryIdsPath ? categoryIdsPath : []);

        onCategoryClick(categoryIdsPath);

        // Resetuje stan rozwiniętych kategorii
        setExpandedCategories({});
    };

    // Funkcja wywoływana przy najechaniu na kategorię
    const handleCategoryMouseEnter = (categoryId) => {
        setHoveredCategoryId(categoryId);
    };

    // Funkcja wywoływana po opuszczeniu kategorii
    const handleCategoryMouseLeave = () => {
        setHoveredCategoryId(null);
    };

    // Funkcja wywołująca funkcję z nadrzędnego komponentu
    const handleSwitchSubcatToCat = () => {
        switchSubcatToCatFromCardsPlain();

        // Resetuje stan rozwiniętych kategorii
        setExpandedCategories({});
    }

    // Funkcja do wybierania odpowiedniej nazwy kategorii w zależności od języka
    const getCategoryNameByLang = (category, lang) => {
        return lang === 'pl' ? category.name : category[`name_${lang}`] || category.name;
    };

    // Renderowanie kategorii
    const renderCategories = (categories, level = 0) => {
        // debugLog('CategoryBox renderCategories categories', 'log', categories);

        // Funkcja wykrywa, czy urządzenie posiada ekran dotykowy
        // const isTouchDevice = () => {
        //     return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        // };
        
        // Sprawdzamy, czy obiekt zawiera którąkolwiek z wartości
        const containsValue = (obj, value) => {
            return Object.values(obj).map(Number).includes(value);
        }

        if ( categories?.length > 0) {
            return categories.map((category, index, array) => (
                <div 
                    key={index + category.id} 
                    style={{ position: 'relative' }}
                    onMouseEnter={() => handleCategoryMouseEnter(category.id)}
                    // onMouseOver={() => handleCategoryMouseEnter(category.id)}
                    onMouseLeave={handleCategoryMouseLeave}
                >
                    <div 
                        onClick={() => handleCategoryClick(category)} 
                        // className={styles["cat-box-subcat-con-btn"]} 
                        className={`${styles["cat-box-subcat-con-btn"]} ${Array.isArray(buukiCategoryIdsPath) && buukiCategoryIdsPath.includes(category.id) ? styles["cat-box-subcat-con-btn-selected"] : ''}`}
                        style={{width: '100%'}}
                    >
                        {getCategoryNameByLang(category, locale)}
                        {category.subcategories && category.subcategories.length > 0 && (
                            <>
                                <span 
                                    onClick={(e) => toggleExpandCategory(e, category.id, level)} 
                                    // onMouseEnter={(e) => toggleExpandCategory(e, category.id, level)}
                                    // onTouchStart={(e) => toggleExpandCategory(e, category.id, level)}
                                    // {...(isTouchDevice() ? { onTouchStart: (e) => toggleExpandCategory(e, category.id, level) } : { onMouseEnter: (e) => toggleExpandCategory(e, category.id, level) })}
                                    // className={styles['expand-icon']}
                                >
                                    <Image 
                                        src={
                                            hoveredCategoryId === category.id || Array.isArray(buukiCategoryIdsPath) && buukiCategoryIdsPath.includes(category.id)
                                            ? IconPaths.expandArrowWhiteIcon
                                            : containsValue(expandedCategories, category.id) 
                                            ? IconPaths.expandArrowBlackIcon
                                            : IconPaths.expandArrowBlackIcon
                                        } 
                                        alt={
                                            hoveredCategoryId === category.id || Array.isArray(buukiCategoryIdsPath) && buukiCategoryIdsPath.includes(category.id)
                                            ? genAltText(IconPaths.expandArrowWhiteIcon)
                                            : containsValue(expandedCategories, category.id) 
                                            ? genAltText(IconPaths.expandArrowBlackIcon)
                                            : genAltText(IconPaths.expandArrowBlackIcon)
                                        } 
                                        width={12} 
                                        height={12} 
                                        className={
                                            hoveredCategoryId === category.id || Object.values(expandedCategories).includes(category.id) 
                                                ? styles["cat-box-subcat-con-con-btn-i-rotate"]
                                                : containsValue(expandedCategories, category.id)
                                                ? styles["cat-box-subcat-con-con-btn-i-no-rotate"]
                                                : styles["cat-box-subcat-con-con-btn-i-no-rotate"]
                                        }
                                    />
                                </span>
                            </>
                        )}
                    </div>
                    {expandedCategories[level] === category.id && category.subcategories && (
                        <div className={styles["cat-box-subcat-con"]}>
                            <div className={expandedCategories[level] === category.id ? styles["cat-box-subcat-visible"] : styles["cat-box-subcat-hidden"]}>
                                {renderCategories(category.subcategories, level + 1)}
                            </div>
                        </div>
                    )}
                    {array.length > 1 && index !== array.length - 1 && (
                        <div className={styles["cat-box-subcat-con-rect"]}></div>
                    )}
                </div>
            ));
        }
    };
   
    // Funkcja do rozwijania i zwijania kategorii
    const toggleExpandCategory = (e, categoryId, level) => {
        e.stopPropagation(); // Zapobiega propagacji do wyższych elementów DOM

        setExpandedCategories(prevState => {
            const isCurrentlyExpanded = prevState[level] === categoryId;
                return {
                ...prevState,
                [level]: isCurrentlyExpanded ? null : categoryId
            };
        });
    };

    // Buduje strukturę kategorii na podstawie categories i filterBuukiCategoryId
    useEffect(() => {
        setStructuredCategories(buildCategoryStructure(categories, filterBuukiCategoryId));
    }, [categories, filterBuukiCategoryId]);
    
    // Do sprawdzania, czy kliknięcie miało miejsce poza komponentem
    useEffect(() => {
        // Funkcja sprawdzająca czy kliknięcie miało miejsce poza komponentem
        const handleClickOutside = (event) => {
            if (categoryBoxRef && !categoryBoxRef.current.contains(event.target)) {
                setExpandedCategories({});
            }
        };
    
        // Dodanie nasłuchiwacza zdarzeń
        document.addEventListener('mousedown', handleClickOutside);
    
        // Usunięcie nasłuchiwacza zdarzeń podczas czyszczenia komponentu
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Ref do elementu głównego komponentu
    const categoryBoxRef = useRef(null);

    return (
        <div ref={categoryBoxRef} style={{ position: 'relative' }}>
            <div className={styles["cat-box-subcat-con-hd"]} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>
                    {
                        filterBuukiCategoryId 
                        ?
                            <FormattedMessage
                                id = "Subcategories"
                                defaultMessage="Podkategorie"
                            />
                        :
                            <FormattedMessage
                                id = "Categories"
                                defaultMessage="Kategorie"
                            />
                    }
                </h3>
                {
                    filterBuukiCategoryId ? 
                    <FaTh onClick={() => handleSwitchSubcatToCat()} style={{ cursor: 'pointer' }} className={styles["cat-box-subcat-switch-subcat-to-cat"]} />
                    :
                    <FaListUl onClick={() => handleSwitchSubcatToCat()} style={{ cursor: 'pointer' }} className={styles["cat-box-subcat-switch-subcat-to-cat"]} />
                }
            </div>
            { 
                structuredCategories?.length > 0 
                ?
                    renderCategories(structuredCategories)
                :   <>
                        <div className={styles["spn-cnt"]} style={{paddingTop: '65px'}}>
                            <Spinner 
                                animation="border" 
                                variant="secondary"
                            /> 
                        </div>
                        <div onClick={() => handleSwitchSubcatToCat()} style={{ cursor: 'pointer' }} className={styles["cat-box-subcat-con-no-subcat"]}>
                            <FormattedMessage
                                id = "NoSubcat"
                                defaultMessage="Brak podkategorii w tej sekcji"
                            />
                        </div>
                    </>
            }
        </div>
    );
};

export default CategoryBox;

// PropTypes
CategoryBox.propTypes = {
    categories: PropTypes.array,
    onCategoryClick: PropTypes.func,
    filterBuukiCategoryId: PropTypes.number,
    switchSubcatToCatFromCardsPlain: PropTypes.func,
  // Definicje dla pozostałych właściwości...
};
