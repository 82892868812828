// src/components/_common/ResizableImage/index.js
import React, { useState, useEffect } from 'react';
// import { Image } from 'react-responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import PropTypes from 'prop-types';

const ResizableImage = ({ imageUrl, width, height, imageAlt, placeholderSrc, borderTopLeftRadius = "0", borderTopRightRadius = "0", borderBottomLeftRadius = "0", borderBottomRightRadius = "0"}) => {
    // <Image src={imageUrl} width={width} height={height} />

    return (
        <LazyLoadImage 
            src={imageUrl}
            width={width} 
            height={height} 
            alt={imageAlt}
            effect="blur"
            placeholderSrc={placeholderSrc}
            webp="true"
            style={{ 
                borderTopLeftRadius: borderTopLeftRadius,
                borderTopRightRadius: borderTopRightRadius,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius
            }}
        /> 
    );
};

// PropTypes
ResizableImage.propTypes = {
    // imageUrl: PropTypes.oneOfType([
    //     PropTypes.string, // akceptuje łańcuchy (URL)
    //     PropTypes.number, // akceptuje liczby
    //     PropTypes.object, // akceptuje obiekty
    //     // Dodaj inne typy, które chcesz obsłużyć
    // ]).isRequired,
    imageUrl: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    borderTopLeftRadius: PropTypes.string,
    borderTopRightRadius: PropTypes.string,
    borderBottomLeftRadius: PropTypes.string,
    borderBottomRightRadius: PropTypes.string,
    imageAlt: PropTypes.string,
    placeholderSrc: PropTypes.string,
}

export default ResizableImage;