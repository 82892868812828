import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { FaRegClock } from 'react-icons/fa';
import PropTypes from 'prop-types';

import styles from './css/opening-hours-modal-box.module.css';

import { Trans } from '@/utils/intlTranslations';
import { renderStatusIcon } from '@/utils';

export const OpeningHoursModalBox = ({ modalTitle, location, openModal, setOpenModal }) => {
    // const [openModal, setOpenModal] = useState(false);

    // Przygotowanie danych dla każdego dnia
    const days = [
        { name: "Monday", open: location.monday_open || '00:00', close: location.monday_close || '00:00' },
        { name: "Tuesday", open: location.tuesday_open || '00:00', close: location.tuesday_close || '00:00' },
        { name: "Wednesday", open: location.wednesday_open || '00:00', close: location.wednesday_close || '00:00' },
        { name: "Thursday", open: location.thursday_open || '00:00', close: location.thursday_close || '00:00' },
        { name: "Friday", open: location.friday_open || '00:00', close: location.friday_close || '00:00' },
        { name: "Saturday", open: location.saturday_open || '00:00', close: location.saturday_close || '00:00' },
        { name: "Sunday", open: location.sunday_open || '00:00', close: location.sunday_close || '00:00' },
    ];

    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-title"
            PaperProps={{
                style: {
                  maxHeight: '80vh',
                  marginBottom: '20vh', // Dostosuj wartość zgodnie z wysokością twojego menu
                },
            }}
        >
            <DialogTitle id="modal-title"><FaRegClock className={styles["opening-hours--ico"]}/>{modalTitle}</DialogTitle>
            <DialogContent style={{ paddingBottom: '0px'}}>
                <div className={styles["opening-hours--sec"]}>
                    {days.map((day) => (
                        <div key={day.name}>
                            <FormattedMessage
                                id={day.name}
                                defaultMessage={Trans(day.name)}
                            />
                            <div className={styles["opening-hours--sec-item"]}>
                                <div>
                                    {day.open} - {day.close}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                
                <hr />
                
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {/* is_always_open */}
                    <div className={styles["opening-hours--sec-checkbox"]}>
                        <div className={styles["opening-hours--sec-checkbox-d"]}>
                            <span style={{marginRight: '-6px', color: '#40466a'}}>
                                <FormattedMessage
                                    id = "is_always_open"
                                    defaultMessage="Czynne całą dobę"
                                />
                            </span>
                            {renderStatusIcon(location.is_always_open)}
                        </div>
                    </div>

                    {/* is_permanently_closed */}
                    <div className={styles["opening-hours--sec-checkbox"]}>
                        <div className={styles["opening-hours--sec-checkbox-d"]}>
                            <span style={{marginRight: '-6px', color: '#40466a'}}>
                                <FormattedMessage
                                    id = "is_permanently_closed"
                                    defaultMessage="Zamknięte na stałe"
                                />
                            </span>
                            {renderStatusIcon(location.is_permanently_closed)}
                        </div>
                    </div>
                </div>
                
                <hr />

                {/* temporary_status */}
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className={styles["opening-hours--sec-hdr"]}>
                        <FormattedMessage
                            id = "temporary_status"
                            defaultMessage="Tymczasowy status"
                        />
                    </div>
                    <div className={styles["opening-hours--sec-txt"]}>
                        { 
                            location.temporary_status != '' ?
                                
                                <div dangerouslySetInnerHTML={{__html: location.temporary_status }}/>
                            :
                                React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak danych') 
                        }
                    </div>
                </div>
                <hr />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Zamknij</Button>
            </DialogActions>
        </Dialog>
    );
}

// PropTypes
OpeningHoursModalBox.propTypes = {
    modalTitle: PropTypes.string,
    location: PropTypes.object,
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
  // Definicje dla pozostałych właściwości...
};