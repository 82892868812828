// src/lib/redux/buukiCategories/ApiAction.js
import ACTION_TYPES from './ActionTypes';

export const fetchData = () => ({
  type: ACTION_TYPES.BUUKI_CATEGORIES_PENDING,
});

export const fetchSuccess = (data) => ({
  type: ACTION_TYPES.BUUKI_CATEGORIES_SUCCESS,
  payload: data,
});

export const fetchError = (error) => ({
  type: ACTION_TYPES.BUUKI_CATEGORIES_ERROR,
  payload: error,
});