// src/components/PromoStarter/index.js
import React from "react";
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';

import styles from './css/promo-starter.module.css';

import { ImagePaths } from '@/constants';
import { genAltText } from '@/utils';

export const PromoStarter = () =>  {
  return (
    <section className={styles["ps"]}>
      <div className={styles["ps--f"]}>
          <div className={styles["ps--f-i"]}>
            <div className={styles["ps--f-i-b"]}>
              <div className={styles["ps--f-i-hdr"]} itemProp="description">
                <FormattedMessage
                    id = "EasilyAddBooksYouOwn"
                    defaultMessage="Łatwo dodawaj książki, które posiadasz"
                />
              </div>
              <div className={styles["ps--f-i-con"]} itemProp="description">
                <FormattedMessage
                    id = "EasilyAddBooksYouOwnDescription"
                    defaultMessage="Specjalnie dla Ciebie przygotowaliśmy rozwiązania, które ułatwią Ci dodawanie książek, którymi chcesz podzielić się z innymi. Wystarczy, że zeskanujesz kod kreskowy bądź uzupełnisz pole ISBN a my resztę informacji uzupełnimy za Ciebie. Nie przejmuj się długimi opisami, czasochłonnym wyszukiwaniem roku wydania i wydawnictwa- nasz portal uzupełni to wszystko za Ciebie. Ty natomiast, będziesz miał więcej czasu by wyszukać następną książkę wartą Twojego czasu!"
                />
              </div>
            </div>
            <Image src={ImagePaths.promoStarterPrimaryLeftImg} alt={genAltText(ImagePaths.promoStarterPrimaryLeftImg)} className={styles["ps--f-img"]} width={250} height={250} />
          </div>
          <div className={styles["ps--f-i"]}>
            <div className={styles["ps--f-i-b"]}>
              <div className={styles["ps--f-i-hdr"]} itemProp="description">
                <FormattedMessage
                  id = "PortalOfEnthusiastsForEnthusiasts"
                  defaultMessage="Portal pasjonatów dla pasjonatów"
                />
              </div>
              <div className={styles["ps--f-i-con"]} itemProp="description">
                <FormattedMessage
                  id = "PortalOfEnthusiastsForEnthusiastsDescription"
                  defaultMessage="Tak jak i Ty, my też kochamy książki. Dlatego chcemy się nimi dzielić z innymi. Nieważne, czy jest to najnowszy hit na polskim rynku, czy stare wydanie małego i zapomnianego wydawnictwa. Szukajksiazki.pl powie ci dokładnie, kto i gdzie posiada pozycję, której szukasz. Dlatego przyszedł czas, byś przypomniał sobie jakiej książki ostatnio nie mogłeś znaleźć i przetestował nasz serwis!"
                />
              </div>
            </div>
            <Image src={ImagePaths.promoStarterPrimaryRightImg} alt={genAltText(ImagePaths.promoStarterPrimaryRightImg)}  className={styles["ps--f-img"]} width={250} height={250} />
          </div>
      </div>
      <div className={styles["ps--f"]}>
          <div className={styles["ps--f-i"]}>
              <div className={styles["ps--f-i-b"]}>
                  <div className={styles["ps--f-i-hdr"]} itemProp="description">
                    <FormattedMessage
                      id = "ServiceForMillionsOfUniquePeople"
                      defaultMessage="Serwis dla milionów wyjątkowych ludzi"
                    />
                  </div>
                  <div className={styles["ps--f-i-con"]} itemProp="description">
                    <FormattedMessage
                      id = "ServiceForMillionsOfUniquePeopleDescription"
                      defaultMessage="Każdy z nas jest wyjątkowy. Dlatego każdy znajdzie w szukajksiazki.pl coś dla siebie. Pragniemy, by serwis przez nas przygotowany spełniał wszelkie zachcianki swoich użytkowników i nie boimy się wyzwań. Wierzymy, że to co udało nam się zrobić, jest jedynie pierwszym krokiem ku czemuś znacznie większemu..."
                    />
                  </div>
              </div>
              <Image src={ImagePaths.promoStarterSecondaryLeftImg} alt={genAltText(ImagePaths.promoStarterSecondaryLeftImg)} className={styles["ps--f-img"]} width={250} height={250} />
          </div>
          <div className={styles["ps--f-i"]}>
            <div className={styles["ps--f-i-b"]}>
                <div className={styles["ps--f-i-hdr"]} itemProp="description">
                  <FormattedMessage
                    id = "ForBookstores"
                    defaultMessage="Profile firmowe zostały wyświetlone ponad 8 milionów razy"
                  />
                </div>
                <div className={styles["ps--f-i-con"]} itemProp="description">
                  <FormattedMessage
                    id = "ForBookstoresDescription"
                    defaultMessage="Profile firmowe zostały wyświetlone ponad 8 milionów zyskując popularność wśród lokalnej społeczności."
                  />
                </div>
            </div>
            <Image src={ImagePaths.promoStarterSecondaryRightImg} alt={genAltText(ImagePaths.promoStarterSecondaryRightImg)} className={styles["ps--f-img"]} width={250} height={250} />
          </div>
      </div>
    </section>
  )
}
