import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { MdDirectionsBus } from 'react-icons/md';
import PropTypes from 'prop-types';

import styles from './css/public-transit-modal-box.module.css';

import { Trans } from '@/utils/intlTranslations';
import { renderStatusIcon } from '@/utils';

export const PublicTransitModalBox = ({ modalTitle, location, openModal, setOpenModal }) => {
    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-title"
            PaperProps={{
                style: {
                  maxHeight: '80vh',
                  marginBottom: '20vh', // Dostosuj wartość zgodnie z wysokością twojego menu
                },
            }}
        >
            <DialogTitle id="modal-title"><MdDirectionsBus className={styles["public-transit--ico"]}/>{modalTitle}</DialogTitle>
            <DialogContent style={{ paddingBottom: '0px'}}>
                <div className={styles["public-transit--sec"]}>
                    {/* public_transit */}
                    <div className={styles["public-transit--sec-wrp"]}>
                        <h2>
                            <FormattedMessage
                                id = "public_transit"
                                defaultMessage="Dojazd komunikacją publiczną"
                            />
                        </h2>
                        {
                            location.public_transit ?
                                <div dangerouslySetInnerHTML={{__html: location.public_transit }}/>
                            :
                            React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                        }
                    </div>

                    <hr />

                    {/* public_transit_description */}
                    <div className={styles["public-transit--sec-wrp"]}>
                        <h2>
                            <FormattedMessage
                                id = "public_transit_description"
                                defaultMessage="Informacje dotyczące dojazdu"
                            />
                        </h2>
                        {
                            location.public_transit_description ?
                                <div dangerouslySetInnerHTML={{__html: location.public_transit_description }}/>
                            :
                            React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') 
                        }
                    </div>
                </div>
                <hr />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Zamknij</Button>
            </DialogActions>
        </Dialog>
    );
}

// PropTypes
PublicTransitModalBox.propTypes = {
    modalTitle: PropTypes.string,
    location: PropTypes.object,
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
  // Definicje dla pozostałych właściwości...
};