// src/lib/redux/buukiCategories/ApiActionCreator.js
import { fetchData, fetchSuccess, fetchError } from './ApiAction';
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

// const fetchBuukiCategories = (page) => async dispatch => {
const fetchBuukiCategories = () => async dispatch => {
  dispatch(fetchData());
  
  new Promise((resolve, reject) => {
    axios
      .get(`/api/buuki-categories`)
      .then((response) => {
        if (response.data) {
          dispatch(fetchSuccess(response.data));
        }
        resolve(response);
      })
      .catch((error) => {
        console.error('Błąd w ApiActionCreator: ', error);
        dispatch(fetchError(error));
        reject(error);
      });
  });
};

export default fetchBuukiCategories;
