// src/components/FindYourBook/index.js
import React, { useState, useEffect, useMemo } from "react";
import { Form, FormControl, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
// import { useRouter } from 'next/navigation';

import styles from './css/find-your-book.module.css';

import { IconPaths, ImagePaths, LogoPaths } from '@/constants';
import { genAltText } from '@/utils';
import { useLanguage } from '@/contexts/LanguageContext';
// import fetchBookInformation from '@//lib/redux/bookInformation/ApiActionCreator';
import fetchBookOffers from '@//lib/redux/bookOffers/ApiActionCreator';
import { useGeolocation } from '@/contexts/GeolocationContext';
import { Trans } from '@/utils/intlTranslations';

import debugLog from '@/helpers/debugLog'; 

export function FindYourBook() {
    const [searchInputValue, setSearchInputValue] = useState('');
    // whether or not to show the loading dialog
    const {locale} = useLanguage();
    const dispatch = useDispatch();
    const { coordinates, handleGeolocationClick } = useGeolocation();
    const router = useRouter();

    // Selektor Reduxa
    // const { data: bookInformationData, loading:  bookInformationIsLoading } = useSelector((state) => state.bookInformation);
    const { data: bookOffersData, loading:  bookOffersIsLoading } = useSelector((state) => state.bookOffers);

    // debugLog('FindYourBook bookOffersData', 'debug', bookOffersData);
    // debugLog('FindYourBook bookInformationData', 'debug', bookInformationData);

    const handleSearchSubmit = (evt) => {
        evt.preventDefault(); // zapobiega odświeżeniu strony
        onSearch(searchInputValue);
    };

    const intl = useIntl();
    const _noSearchDataIntl = intl.formatMessage({ id: "NoSearchData" });
    const _noInformationFoundIntl = intl.formatMessage({ id: "NoInformationFound" });
    const onSearch = (searchTerm) => {
        // console.log(`Wyszukiwanie: ${searchTerm}`);
        if ( searchTerm.length === 0 ) {
            toast.error(_noSearchDataIntl);
            // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchTerm, 1));

            // Aktualizuj URL, dodając parametr wyszukiwania
            // router.push(`?wyszukaj=${encodeURIComponent(searchTerm)}`);
        }else {
            // dispatch(fetchBookInformation(coordinates.latitude, coordinates.longitude, searchTerm, 1));
            dispatch(fetchBookOffers(coordinates.latitude, coordinates.longitude, searchTerm, 1));
            // toast.error(_noInformationFoundIntl);

            // Aktualizuj URL, dodając parametr wyszukiwania
            router.push(`?wyszukaj=${encodeURIComponent(searchTerm)}`);
            // setSearchInputValue('');
        }
    };

    const updateInputValue = (evt) => {
        const val = evt.target.value; 
        setSearchInputValue(val);
    }

    // cookies
    // useEffect(() => { 
    //     cookieNames.forEach(cookieName => {
    //         if (cookies.get(cookieName)) {
    //             cookies.remove(cookieName);
    //         }
    //     });
    // }, [cookies]);

    return (
        <>
            <div className={styles["fyb--sec"]}>
                <div className={styles["fyb--hdrg"]}>
                    <div className={styles["fyb--t"]}>
                        <div className={styles["fyb--hdrg-t"]}>
                            <Image 
                                src={LogoPaths.searchBookLogo} 
                                alt={genAltText(LogoPaths.searchBookLogo)} 
                                width={354} 
                                height={50}
                                // loading="eager"
                                loading="lazy"
                                // priority={true}
                                // fill={true}
                                className={styles["fyb--i"]} 
                            />
                        </div>
                        <div className={styles["fyb--shdrg-t"]}>
                            <FormattedMessage
                                id = "SeeWhatsHappeningInLocalBookstores"
                                defaultMessage="i zobacz, co dzieje się w okolicznych księgarniach!"
                            />
                        </div>
                    </div>
                    <div className={styles["fyb--wrp"]}>
                        <div className={styles["fyb--s-b"]}>
                            {/* <Form inline className={styles["input-group"]}> */}
                            {/* <Form className={styles["input-group"]}> */}
                            <Form className={"input-group"} onSubmit={handleSearchSubmit}>
                                <FormControl 
                                    type="text" 
                                    value={searchInputValue} 
                                    onChange={evt => updateInputValue(evt)} 
                                    placeholder={Trans('TitleYouAreLookingFor')} 
                                    className={styles["fyb--s-i"]} /*className={`${styles['mr-sm-2']} ${styles['fyb--s-i']}`}*/ 
                                />
                                <Image src={IconPaths.searchIcon} alt={genAltText(IconPaths.searchIcon)} width={20} height={20} className={styles["fyb--s-ic"]} />
                                <Button type="submit" variant="outline-success" className={styles["fyb--s-btn"]}>
                                    <FormattedMessage id = "Search" defaultMessage="Szukaj"/>
                                </Button>
                            </Form>
                        </div>
                        <div className={styles["fyb--s-b"]}>
                            <Button className={styles["fyb--geo-btn"]} onClick={()=> {handleGeolocationClick()}}>
                                <FormattedMessage id = "FindMe" defaultMessage="Zlokalizuj mnie"/>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles["fyb--i"]}>
                    <Image 
                        src={ImagePaths.starterPageImg} 
                        alt={genAltText(ImagePaths.starterPageImg)} 
                        width={998} 
                        height={310}
                        priority={true}
                        // loading="lazy"
                        // fill={true}
                        className={styles["fyb--ii"]} 
                        loading="eager"
                    />
                </div>
            </div>
        </>
    );
}