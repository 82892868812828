
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[[...params]]",
      function () {
        return require("private-next-pages/[[...params]].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[[...params]]"])
      });
    }
  