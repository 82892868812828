// src/components/_common/SelectSortBox/index.js
import React from 'react';
import { FormattedMessage } from 'react-intl';
// import Image from 'next/image';
import PropTypes from 'prop-types';

import styles from './css/select-sort-box.module.css';

// import { IconPaths } from '../../../constants';
// import { genAltText } from '../../../utils';

const SelectSortBox = ({ selectedOption, onSortChange, sortOptions }) => {
    return (
        <div className={styles["s-s-b--con"]}>
            <div className={styles["s-s-b--con-select"]}>
                <FormattedMessage
                    id="SortBy"
                    defaultMessage="Sortuj po:"
                />:
                <select value={selectedOption} onChange={onSortChange}>
                    {sortOptions.map(option => (
                        <option key={option.value} value={option.value}>
                            <FormattedMessage
                                id={option.translationId}
                                defaultMessage={option.defaultMessage}
                            />
                        </option>
                    ))}
                </select>
                {/* {' '}
                <Image src={IconPaths.sortArrowsIcon} alt={genAltText(IconPaths.sortArrowsIcon)} width={9} height={15} className={styles["s-s-b--con-select-ico"]} /> */}
            </div>
        </div>
    );
};

SelectSortBox.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    onSortChange: PropTypes.func.isRequired,
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        translationId: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    })).isRequired
};

export default SelectSortBox;
