// src/components/CardsPlain/index.js
import React, { useState, useEffect, useMemo, useContext, useCallback } from "react";
import { useRouter } from 'next/router';
// import { useRouter } from 'next/navigation';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import styles from './css/cards-plain.module.css';

import { AddBookFooter } from '@/components/AddBookFooter';
import CategoryBox from '@/components/CategoryBox';
import buukiCategories from '@/lib/redux/buukiCategories/ApiActionCreator';
import { enterpriseCategoryItems, mappingCategoryIdToPath, PATH_COMPONENT_MAP } from '@/constants/mappings'; 
import { useLanguage } from '@/contexts/LanguageContext';
import { useBreadcrumbs } from '@/contexts/BreadcrumbsContext';
import { Breadcrumbs } from '@/components/_common/Breadcrumbs';
import { useCategory } from '@/contexts/CategoryContext';

// import { usePrevious } from '@/utils';
import debugLog from '@/helpers/debugLog'; 

const CardsPlain = () => {
    const cookies = useMemo(() => new Cookies(), []);
    const [userLatitude, setUserLatitude] = useState(cookies.get('userLatitude'));
    const [userLongitude, setUserLongitude] = useState(cookies.get('userLongitude'));
    const {setBreadcrumbs} = useBreadcrumbs();
    const {locale, selectLanguage, userSelectedLanguage} = useLanguage();
    const [filterBuukiCategoryId, setFilterBuukiCategoryId] = useState(null);
    const {buukiCategoryIdsPath} = useCategory();
    const router = useRouter();
    const location = router.asPath;
    const dispatch = useDispatch();
    const intl = useIntl();

    // debugLog('CardsPlain buukiCategoryIdsPath', 'debug', JSON.stringify(buukiCategoryIdsPath));

    // const prevLatitude = usePrevious(userLatitude);
    // const prevLongitude = usePrevious(userLongitude);

    // Selektor Reduxa
    const { data: bookInformationData, loading: bookInformationIsLoading } = useSelector((state) => state.bookInformation);
    const { data: buukiCategoriesData, loading: buukiCategoriesIsLoading } = useSelector((state) => state.buukiCategories);

    // debugLog('CardsPlain bookInformationData', 'debug', bookInformationData);

    // Funkcja do pobierania ścieżki dla danego ID kategorii i locale
    const getPathForCategoryIdAndLocale = (categoryId, lang) => {
        // Pobieramy odpowiedni obiekt pathTranslations dla danego ID
        const pathTranslations = mappingCategoryIdToPath[categoryId];
        
        if (!pathTranslations) {
            throw new Error('No path translations found for the given category ID.');
        }
    
        // Pobieramy ścieżkę dla danego lang
        const path = pathTranslations[lang];
    
        if (!path) {
            throw new Error('No path found for the given lang.');
        }
    
        return path;
    }

    // Logika, co się dzieje po kliknięciu na kategorię
    const handleCategoryClick = (categoryIdsPath) => {
        debugLog('CardsPlain handleCategoryClick categoryIdsPath', 'debug', categoryIdsPath);
        // jeśli kliknie się kategorię/podkategorię to wykonaj routing na nadrzędną kategorię główną (lvl = 0)
        if (categoryIdsPath[0]) {
            const routerPush = getPathForCategoryIdAndLocale(categoryIdsPath[0], locale);
            debugLog('CardsPlain handleCategoryClick categoryIdsPath 2', 'debug', routerPush);
            router.push(routerPush, undefined, { shallow: true });
        }
    };

    const switchToMainCategory = useCallback(() => {
        setFilterBuukiCategoryId(null);
    }, [setFilterBuukiCategoryId]); // Dodaj wszystkie zależności tutaj

    const switchSubcatToCat = useCallback(() => { 
        debugLog('CardsPlain switchSubcatToCat filterBuukiCategoryId buukiCategoryIdsPath', 'debug', filterBuukiCategoryId + " | " +  buukiCategoryIdsPath);
        if (filterBuukiCategoryId) {
          setFilterBuukiCategoryId(null);
        } else {
          setFilterBuukiCategoryId(buukiCategoryIdsPath[0] || null);
        }
    }, [filterBuukiCategoryId, buukiCategoryIdsPath]); // Dodaj wszystkie zależności tutaj

    // dispatch(buukiCategories())
    useEffect(() => { 
        dispatch(buukiCategories());
    },[dispatch]);

    // Przewija okno przeglądarki do góry w sposób płynny po zmianie trasy. 
    // Inicjalizuje stan użytkownika z wartościami szerokości i długości geograficznej z ciasteczek.
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        // scroll to the top of the browser window when changing route
        // the window object is a normal DOM object and is safe to use in React.

        setUserLatitude(cookies.get('userLatitude'));
        setUserLongitude(cookies.get('userLongitude'));

    }, [cookies, userSelectedLanguage, userLatitude, userLongitude]);    

    // Konfiguruje ścieżkę nawigacyjną (breadcrumbs) z aktualną lokalizacją i językiem. 
    // W trakcie odmontowywania komponentu, ścieżka nawigacyjna jest czyszczona.
    useEffect(() => {
        // Funkcja do wybierania odpowiedniej nazwy kategorii w zależności od categoryId i języka
        const getCategoryNameByCategoryId = (categories, categoryId, lang) => {
            let currentCategory = categories.find(category => category.id === categoryId);
            return lang === 'pl' ? currentCategory.name : currentCategory[`name_${lang}`] || currentCategory.name;
        };

        const mainCategoriesIntl = intl.formatMessage({ id: "MainCategories" });
        setBreadcrumbs([
            { title: mainCategoriesIntl, onClick: switchToMainCategory },
            ...buukiCategoryIdsPath.map(item => ({
                title: buukiCategoriesData?.length > 0 ? getCategoryNameByCategoryId(buukiCategoriesData, item, locale) : '',
                href: location 
            }))
        ]);

        return () => setBreadcrumbs([]); // Czyszczenie breadcrumbs przy odmontowywaniu
    }, [buukiCategoryIdsPath, buukiCategoriesData, intl, location, setBreadcrumbs, switchToMainCategory, router.asPath, locale]);    

    // Zmienia język strony na podstawie ścieżki url
    // np. jak w przeglądarce wywołam http://localhost:3000/podii
    // to ustawi mi się język ukraiński na stronie dla wydarzeń
    useEffect(() => {
        if (!userSelectedLanguage) {
            debugLog('CardsPlain userSelectedLanguage', 'debug', userSelectedLanguage);
            const path = router.asPath;
            const findLanguageByPath = (path) => {
                for (let i = 0; i < enterpriseCategoryItems.length; i++) {
                    const item = enterpriseCategoryItems[i];
                    const languageKeys = Object.keys(item.path);
                    for (let j = 0; j < languageKeys.length; j++) {
                        const key = languageKeys[j];
                        if (item.path[key] === path) {
                            return key;
                        }
                    }
                }
            };
            const language = findLanguageByPath(path);
            if (language) {
                selectLanguage(language);
            }
        }
    }, [router.asPath, selectLanguage, userSelectedLanguage, locale]);

    useEffect(() => { 
        setFilterBuukiCategoryId(buukiCategoryIdsPath[0] || null);
    },[buukiCategoryIdsPath]);

    const Component = PATH_COMPONENT_MAP[location];
    
    return (  
        <>
            <Breadcrumbs /> 
            <div className={styles["cp--con"]}>
                <div className={styles["cp--cnt-filters"]}>
                    <CategoryBox
                        categories={buukiCategoriesData}
                        onCategoryClick={handleCategoryClick}
                        filterBuukiCategoryId={filterBuukiCategoryId}
                        switchSubcatToCatFromCardsPlain={switchSubcatToCat}
                    />
                </div>
                <div className={styles["cp--cnt-activities"]}>
                    {Component && <Component bookInformationData={bookInformationData} isCardsPlain={true} />}
                </div >
            </div>
            <AddBookFooter />
        </>
    );
};

export default CardsPlain;
