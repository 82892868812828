// src/pages/[[...params]].js
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useRouter } from 'next/router';
// import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CardsPlain from '@/components/CardsPlain';
import CardDetails from '@/components/CardDetails';
import StarterPage from '@/components/StarterPage';
import { CardsGrid } from '@/components/CardsGrid';

import Layout from "@/components/_common/Layout/Layout";
import { YourResults } from '@/components/YourResults';
import { AddBookFooter } from '@/components/AddBookFooter';
import PageNotFound from '@/components/_common/PageNotFound';
import { ImagePaths } from '@/constants';
import { extractBookOffersDetails } from '@/lib/api/extractBookOffersDetails';
import { removeHTMLTagsAndTruncate } from '@/utils';
import { 
  segmentToMetaTags, 
  validPlainPaths, 
  validPaths,
} from '../constants/mappings';
import { useLanguage } from '../contexts/LanguageContext';
import { serializeToJson } from '@/helpers/serializeToJson';
import StructuredData from '@/components/_common/StructuredData';

import debugLog from '@/helpers/debugLog';

const DynamicRoute = ({ metaTags = {}, bookDetails }) => {
  // debugLog('DynamicRoute metaTags', 'debug', metaTags);
  debugLog('DynamicRoute bookDetails', 'debug', bookDetails);
  const [jsonSchemaString, setJsonSchemaString] = useState(false);

  // Selektor Reduxa
  const { data: bookInformationData, loading:  bookInformationIsLoading } = useSelector((state) => state.bookInformation);
  const { data: bookOffersData, loading:  bookbookOffersIsLoading } = useSelector((state) => state.bookOffers);

  const router = useRouter();
  const { wyszukaj } = router.query;
  // Ustawienie domyślnej wartości dla params
  const params = useMemo(() => {
    return router.query.params || [];
  }, [router.query.params]);
  const {selectLanguage, userSelectedLanguage} = useLanguage();

  // Zmiana języka na podstawie ścieżki
  useEffect(() => {
    // console.log("DynamicRoute => segmentToMetaTags => ", JSON.stringify(segmentToMetaTags));
    // console.log("DynamicRoute => validPlainPaths => ", JSON.stringify(validPlainPaths));
    // console.log("DynamicRoute => validPaths => ", JSON.stringify(validPaths));
    if (params && params.length > 0 && isValidPath(params[0], 'book')) {
        if (validPaths.hasOwnProperty(params[0])) {
            const languageCode = validPaths[params[0]].language;
            if (!userSelectedLanguage) {
                selectLanguage(languageCode);
            }
        }
    }
  }, [params, selectLanguage, userSelectedLanguage]);

  useEffect(() => {
    // YourResults
    if (typeof wyszukaj === 'string' && params.length === 0) {
      debugLog('jsonSchemaString YourResults', 'debug', jsonSchemaString);
      setJsonSchemaString(serializeToJson(searchResultsSchema));
    }else
    // StarterPage
    if (!Array.isArray(params) || params.length === 0) {
      debugLog('jsonSchemaString StarterPage', 'debug', jsonSchemaString);
      setJsonSchemaString(serializeToJson(starterPageSchema));
    }else
    // bookDetails
    if (isValidPath(params[0], 'book')) {
      debugLog('jsonSchemaString bookDetails', 'debug', jsonSchemaString);
      setJsonSchemaString(serializeToJson(bookSchema));
    }
    // debugLog('jsonSchemaString useEffect', 'debug', jsonSchemaString);
    // console.log('jsonSchemaString useEffect', jsonSchemaString);
}, [wyszukaj, params, bookSchema, searchResultsSchema, starterPageSchema, jsonSchemaString]);

  const renderContent = () => {
    // Sprawdzenie, czy wyszukaj nie jest puste
    // if (typeof wyszukaj === 'string' && wyszukaj.trim() !== '') {
    if (typeof wyszukaj === 'string' && params.length === 0) {
      debugLog('renderContent [[...params]] wyszukaj YourResults', 'debug', wyszukaj);
      return (
        <YourResults searchPhrase={wyszukaj} booksData={bookOffersData} />
      );
    }
    else
    // Sprawdzenie, czy params jest tablicą i ma odpowiednią długość
    if (!Array.isArray(params) || params.length === 0) {
      debugLog('renderContent [[...params]] StarterPage', 'debug', params);
      return <StarterPage />;
    }
    else
    if (isValidPath(params[0], 'plain')) {
      debugLog('renderContent [[...params]] isValidPath plain', 'debug', params);
      return <CardsPlain />;
    }
    else
    if (isValidPath(params[0], 'book')) {
      debugLog('renderContent [[...params]] isValidPath book', 'debug', params);
      return renderBookDetails(params, bookDetails) || renderNotFoundWithAddBookFooter();
    }

    return renderNotFoundWithAddBookFooter();
  };

  // 
  // application/ld+json
  //
  const starterPageSchema = useMemo(() => {
    const itemListElement = bookOffersData.map((book, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@context": "https://schema.org",
        "@type": "Book",
        "name": book.title || "Tytuł wkrótce dostępny",
        ...(book.subtitle && { "alternativeHeadline": book.subtitle }),
        ...(book.issue_number && { "bookEdition": book.issue_number }),
        ...(book.format && { "bookFormat": book.format }),
        ...(book.page_count && { "numberOfPages": book.page_count }),
        ...(book.publisher && { "publisher": book.publisher }),
        ...(book.isbn && { "isbn": book.isbn }),
        "description": book.description || book.short_description || "Szczegółowy opis wkrótce",
        "author": book.authors ? book.authors.split(',').map(author => ({
            "@type": "Person",
            "name": author.trim()
        })) : [],
        ...(book.picture_url && { "image": process.env.NEXT_PUBLIC_APP_URL + book.picture_url }),
        ...(book.languages && { "inLanguage": book.languages }),
        ...(book.year && { "datePublished": book.year }),
        ...(book.category && { "genre": book.category }),
        ...(book.condition_description && { "additionalProperty": {
            "@type": "PropertyValue",
            "name": "Condition description",
            "value": book.condition_description
        }}),
        "offers": {
            "@type": "Offer",
            "price": book.price,
            "priceCurrency": "PLN", // Zakładam, że cena jest podana w złotówkach
            "eligibleRegion": {
              "@type": "Country",
              "name": "Polska" // Nazwa regionu, dla którego oferta jest ważna
            },
            "category": "purchase" // Kategoria oferty
        },
        "offers": {
          "@type": "Offer",
          "price": book.price,
          "priceCurrency": "PLN", // Zakładam, że cena jest podana w złotówkach
          "itemCondition": book.condition === "Nowa" ? "http://schema.org/NewCondition" : "http://schema.org/UsedCondition",
          "additionalProperty": {
            "@type": "PropertyValue",
            "name": "Opis stanu książki",
            "value": book.condition_description || "Brak dodatkowego opisu stanu"
          },
          "seller": {
            "@type": "Organization", // Można zmienić na "Person" jeśli to osoba fizyczna
            "name": book.location?.name,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": book?.location?.street,
                "addressLocality": book?.location?.city,
                "addressRegion": book?.location?.state,
                "postalCode": book?.location?.postcode,
                "addressCountry": book?.location?.country
            },
            "telephone": book?.location?.phone,
            "email": book?.location?.email,
            "url": book?.location?.www
          },
          "eligibleRegion": {
            "@type": "Country",
            "name": "Polska" // Nazwa regionu, dla którego oferta jest ważna
          },
          "category": "purchase", // Kategoria oferty
      }
      }
    }));
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": `Platforma dla Pasjonatów Książek - ${process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME}`,
      "description": `${process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} to nowe rozwiązanie dla pasjonatów książek. To platforma do szukania, kupowania i wymieniania się książkami. Oferuje łatwe dodawanie książek, wyszukiwanie rzadkich tytułów i jest otwarte dla indywidualnych użytkowników oraz księgarń.`,
      "about": {
          "@type": "Thing",
          "description": `${process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} to serwis dla milionów wyjątkowych ludzi, gdzie każdy znajdzie coś dla siebie. Pragniemy spełniać zachcianki użytkowników i nie boimy się wyzwań. Nasza misja to wspieranie pasjonatów książek i małych księgarni.`
      },
      "provider": {
          "@type": "Organization",
          "name": `${process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME}`,
          "description": "Portal pasjonatów dla pasjonatów. Dzielimy się miłością do książek z naszą społecznością. Serwis jest miejscem, gdzie można znaleźć zarówno najnowsze hity, jak i rzadkie wydania."
      },
      "potentialAction": {
          "@type": "SearchAction",
          // "target": process.env.NEXT_PUBLIC_APP_URL + "/search?query={search_term_string}",
          "target": process.env.NEXT_PUBLIC_APP_URL + "/?wyszukaj={search_term_string}",
          "query-input": "required name=search_term_string"
      },
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": itemListElement
      }
    }
  }, [bookOffersData]);
  const searchResultsSchema = useMemo(() => {
    return {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": bookOffersData.map((book, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "item": {
              "@context": "https://schema.org",
              "@type": "Book",
              "name": book.title || "Tytuł wkrótce dostępny",
              ...(book.subtitle && { "alternativeHeadline": book.subtitle }),
              ...(book.issue_number && { "bookEdition": book.issue_number }),
              ...(book.format && { "bookFormat": book.format }),
              ...(book.page_count && { "numberOfPages": book.page_count }),
              ...(book.publisher && { "publisher": book.publisher }),
              ...(book.isbn && { "isbn": book.isbn }),
              "description": book.description || book.short_description || "Szczegółowy opis wkrótce",
              "author": book.authors ? book.authors.split(',').map(author => ({
                  "@type": "Person",
                  "name": author.trim()
              })) : [],
              ...(book.picture_url && { "image": process.env.NEXT_PUBLIC_APP_URL + book.picture_url }),
              ...(book.languages && { "inLanguage": book.languages }),
              ...(book.year && { "datePublished": book.year }),
              ...(book.category && { "genre": book.category }),
              ...(book.condition_description && { "additionalProperty": {
                  "@type": "PropertyValue",
                  "name": "Condition description",
                  "value": book.condition_description
              }}),
              "offers": {
                  "@type": "Offer",
                  "price": book.price,
                  "priceCurrency": "PLN", // Zakładam, że cena jest podana w złotówkach
                  "eligibleRegion": {
                    "@type": "Country",
                    "name": "Polska" // Nazwa regionu, dla którego oferta jest ważna
                  },
                  "category": "purchase" // Kategoria oferty
              },
              "offers": {
                "@type": "Offer",
                "price": book.price,
                "priceCurrency": "PLN", // Zakładam, że cena jest podana w złotówkach
                "itemCondition": book.condition === "Nowa" ? "http://schema.org/NewCondition" : "http://schema.org/UsedCondition",
                "additionalProperty": {
                  "@type": "PropertyValue",
                  "name": "Opis stanu książki",
                  "value": book.condition_description || "Brak dodatkowego opisu stanu"
                },
                "seller": {
                  "@type": "Organization", // Można zmienić na "Person" jeśli to osoba fizyczna
                  "name": book.location?.name,
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": book?.location?.street,
                      "addressLocality": book?.location?.city,
                      "addressRegion": book?.location?.state,
                      "postalCode": book?.location?.postcode,
                      "addressCountry": book?.location?.country
                  },
                  "telephone": book?.location?.phone,
                  "email": book?.location?.email,
                  "url": book?.location?.www
                },
                "eligibleRegion": {
                  "@type": "Country",
                  "name": "Polska" // Nazwa regionu, dla którego oferta jest ważna
                },
                "category": "purchase", // Kategoria oferty
            }
          }
      }))
    }
  }, [bookOffersData]);
  const bookSchema = useMemo(() => {
    return {
      "@context": "https://schema.org",
      "@type": "Book",
      "name": bookDetails?.[0]?.title || "Brak tytułu",
      ...(bookDetails?.[0]?.subtitle && { "alternativeHeadline": bookDetails?.[0]?.subtitle }),
      ...(bookDetails?.[0]?.issue_number && { "bookEdition": bookDetails?.[0]?.issue_number }),
      ...(bookDetails?.[0]?.format && { "bookFormat": bookDetails?.[0]?.format }),
      ...(bookDetails?.[0]?.page_count && { "numberOfPages": bookDetails?.[0]?.page_count }),
      ...(bookDetails?.[0]?.publisher && { "publisher": bookDetails?.[0]?.publisher }),
      ...(bookDetails?.[0]?.isbn && { "isbn": bookDetails?.[0]?.isbn }),
      "description": bookDetails?.[0]?.description || bookDetails?.[0]?.short_description || "Brak opisu",
      "author": bookDetails?.[0]?.authors ? bookDetails?.[0]?.authors.split(',').map(author => ({
          "@type": "Person",
          "name": author.trim()
      })) : [],
      ...(bookDetails?.[0]?.picture_url && { "image": process.env.NEXT_PUBLIC_APP_URL + bookDetails?.[0]?.picture_url }),
      ...(bookDetails?.[0]?.languages && { "inLanguage": bookDetails?.[0]?.languages }),
      ...(bookDetails?.[0]?.year && { "datePublished": bookDetails?.[0]?.year }),
      ...(bookDetails?.[0]?.category && { "genre": bookDetails?.[0]?.category }),
      "offers": {
          "@type": "Offer",
          "price": bookDetails?.[0]?.price,
          "priceCurrency": "PLN", // Zakładam, że cena jest podana w złotówkach
          "itemCondition": bookDetails?.[0]?.condition === "Nowa" ? "http://schema.org/NewCondition" : "http://schema.org/UsedCondition",
          "additionalProperty": {
            "@type": "PropertyValue",
            "name": "Opis stanu książki",
            "value": bookDetails?.[0]?.condition_description || "Brak dodatkowego opisu stanu"
          },
          "seller": {
            "@type": "Organization", // Można zmienić na "Person" jeśli to osoba fizyczna
            "name": bookDetails?.[0]?.location?.name,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": bookDetails?.[0]?.location?.street,
                "addressLocality": bookDetails?.[0]?.location?.city,
                "addressRegion": bookDetails?.[0]?.location?.state,
                "postalCode": bookDetails?.[0]?.location?.postcode,
                "addressCountry": bookDetails?.[0]?.location?.country
            },
            "telephone": bookDetails?.[0]?.location?.phone,
            "email": bookDetails?.[0]?.location?.email,
            "url": bookDetails?.[0]?.location?.www
          },
          "eligibleRegion": {
            "@type": "Country",
            "name": "Polska" // Nazwa regionu, dla którego oferta jest ważna
          },
          "category": "purchase", // Kategoria oferty
      }
    }
  }, [bookDetails]);
  // 
  // END application/ld+json
  // 

  return (
    <>
      {/* <Head> */}
        {/* Metadane Open Graph */}
        {/* <meta property="og:title" content={metaTags?.ogTitle || "Znajdziesz tu każdą książkę - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} />
        <meta property="og:description" content={metaTags?.ogDescription || "Twoje najnowocześniejsze źródło informacji na temat wszystkiego, co lokalne - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} />
        <meta property="og:image" content={metaTags?.ogImage || process.env.NEXT_PUBLIC_APP_URL + ImagePaths.adsImg} />
        <meta property="og:image:url" content={metaTags?.ogImage || process.env.NEXT_PUBLIC_APP_URL + ImagePaths.adsImg} />
        <meta property="og:image:secure_url" content={metaTags?.ogImage || process.env.NEXT_PUBLIC_APP_URL + ImagePaths.adsImg} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="og:url" content={metaTags?.ogUrl || process.env.NEXT_PUBLIC_APP_URL} />
        <meta property="og:site_name" content={process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME} />

        <meta property="og:locale" content="pl_PL" />
        <meta property="og:locale:alternate" content="en_PL" />
        <meta property="og:locale:alternate" content="de_PL" />
        <meta property="og:locale:alternate" content="uk_PL" />
        <meta property="og:type" content="website" /> */}
        {/* <meta property="og:type" content="book" /> */}

        {/* Tutaj dodajemy dane strukturalne w tagu script typu application/ld+json */}
        {/* {bookDetails && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: jsonString }}
          />
        )} */}
      {/* </Head> */}
      {/* Tutaj dodajemy dane strukturalne w tagu script typu application/ld+json */}
      {jsonSchemaString && <StructuredData data={jsonSchemaString}/> }
      <Layout metaTags={metaTags} cssLayoutContainer={"layout-container"}>{renderContent()}</Layout>
    </>
  );
};

const isValidPath = (path, type) => {
  if (type === 'plain') {
    return validPlainPaths.includes(path);
  } else {
    // Sprawdzanie, czy ścieżka istnieje i czy typ się zgadza
    return validPaths.hasOwnProperty(path) && validPaths[path].type === type;
  }
};

const renderBookDetails = (params, bookDetails) => {
  // format/:publisher?/:category?/:subcategory?/title/:authors/:isbn?:id
  const segmentMapping = {
    9: ["path", "format", "publisher", "category", "subcategory", "title", "authors", "isbn", "id"],
    8: ["path", "format", "publisher", "category", "title", "authors", "isbn", "id"],
    7: ["path", "format", "publisher", "title", "authors", "isbn", "id"],
    6: ["path", "format", "publisher", "title", "isbn", "id"],
    5: ["path", "format", "title", "isbn", "id"],
    4: ["path", "title", "isbn", "id"],
    3: ["path", "title", "id"],
  };
  const segments = segmentMapping[params.length];

  if (!segments) {
    return null;
  }

  const bookUrlPathData = Object.fromEntries(segments.map((key, index) => [key, params[index]]));
  const { title, id } = bookUrlPathData;

  if (!title || !id) {
    return null;
  }

  return <CardDetails bookUrlPathData={bookUrlPathData} bookDetailsData={bookDetails}/>;
};

const renderNotFoundWithAddBookFooter = () => (
  <>
    <PageNotFound />
    <AddBookFooter />
  </>
);

export default DynamicRoute;

export async function getServerSideProps(context) {
  // Tutaj możesz uzyskać dostęp do context, aby pobrać dane z URL, parametrów zapytania itp.
  // const { params } = context.query; // Pobranie parametrów z URL
  const { resolvedUrl, query } = context;
  let bookDetails = [];

  const params = query.params || [];
  if (isValidPath(params[0], 'book') && params.length > 2) {                    
    let bookOffersId = resolvedUrl.slice(resolvedUrl.lastIndexOf('/') + 1); 
    bookDetails = await extractBookOffersDetails(bookOffersId);
    if (bookDetails === undefined) {
      bookDetails = null; // Zastąp 'undefined' na 'null'
    }
  }

  // Wyodrębnienie logiki generowania meta tagów do funkcji pomocniczej
  const updatedMetaTags = generateMetaTags(resolvedUrl, bookDetails);

  return { props: { metaTags: updatedMetaTags, bookDetails } };
}

// Funkcja pomocnicza do generowania meta tagów
function generateMetaTags(resolvedUrl, bookDetails = null) {
  // Domyślne meta tagi
  const defaultMetaTags = {
    title: bookDetails?.length > 0 && bookDetails[0].title ? bookDetails[0].title + " - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME: "Szeroki wybór książek w lokalnych księgarniach - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME,
    description: bookDetails?.length > 0 && bookDetails[0].short_description ? removeHTMLTagsAndTruncate(bookDetails[0].short_description, 160) : bookDetails?.length > 0 && bookDetails[0].description ? removeHTMLTagsAndTruncate(bookDetails[0].description, 160) : "Odkryj szeroki wybór książek w lokalnych księgarniach. Znajdź i kup swoje ulubione tytuły w najbliższym punkcie sprzedaży. Łączymy miłośników książek z najlepszymi źródłami literatury...",
    ogTitle: bookDetails?.length > 0 && bookDetails[0].title ? bookDetails[0].title + " - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME: "Szeroki wybór książek w lokalnych księgarniach - " + process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME,
    ogDescription: bookDetails?.length > 0 && bookDetails[0].short_description ? removeHTMLTagsAndTruncate(bookDetails[0].short_description, 160) : bookDetails?.length > 0 && bookDetails[0].description ? removeHTMLTagsAndTruncate(bookDetails[0].description, 160) : "Odkryj szeroki wybór książek w lokalnych księgarniach. Znajdź i kup swoje ulubione tytuły w najbliższym punkcie sprzedaży. Łączymy miłośników książek z najlepszymi źródłami literatury...",
    ogImage: bookDetails?.length > 0 && bookDetails[0].picture_url ? getImage(bookDetails[0]) : `${process.env.NEXT_PUBLIC_APP_URL}${ImagePaths.starterPageImg}`,
    ogUrl: `${process.env.NEXT_PUBLIC_APP_URL}${resolvedUrl}`
  };

  // Wyodrębnianie segmentów URL
  const segments = resolvedUrl.split('/').filter(Boolean); // Usuwa puste segmenty

  if (segments.length === 0) {
    return defaultMetaTags;
  }

  // metaTags
  const firstSegment = segments[0];
  if (firstSegment in segmentToMetaTags) {
    const segmentTitle = segmentToMetaTags[firstSegment];
    return {
      ...defaultMetaTags,
      title: `${segmentTitle} - ${process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME}`,
      description: `${segmentTitle} - platforma do szukania, kupowania i wymieniania się książkami`,
      // Możliwe dodatkowe dostosowania

      // platforma do szukania, kupowania i wymieniania się książkami
    };
  }

  // Domyślne meta tagi, jeśli żaden warunek nie zostanie spełniony
  return defaultMetaTags;
}

const getImage = (data) => {
  return `${process.env.NEXT_PUBLIC_APP_URL}${data.picture_url}` || '';
}; 

// PropTypes
DynamicRoute.propTypes = {
  metaTags: PropTypes.object,
  bookDetails: PropTypes.array,
};

// 
// wytłumaczenie działania
// 
/*
  Plik `[[...params]].js` w folderze `pages` w projekcie Next.js jest specjalnym rodzajem dynamicznej ścieżki, która może przyjmować dowolną liczbę segmentów w URL. Dzięki temu jeden plik może obsługiwać wiele różnych tras.

  Oto, jak to działa i jak jest używany w twoim projekcie:

  ### Zasada działania dynamicznych tras:

  - `[[...params]]` jest opcjonalną ścieżką catch-all, która pasuje do wszystkich tras na danym poziomie i niżej.
  - Na przykład, może pasować do `/`, `/events`, `/events/id`, itp.
  - Możesz wyodrębnić te parametry za pomocą `useRouter` hooka od Next.js, który zwraca `query` obiekt, w tym `params` jako tablicę segmentów URL.

  ### W twoim kodzie:

  1. Używasz `getServerSideProps`:
    - To funkcja specyficzna dla Next.js, która uruchamia się na serwerze przed renderowaniem strony. Jest używana do przygotowania danych potrzebnych na danej stronie.
    - Pobierasz z niej `resolvedUrl` i `query`, aby określić, jaka treść powinna być renderowana i jakie dane powinny być pobrane.

  2. Funkcja `isValidPath`:
    - Określa, czy ścieżka z URL jest ważną trasą dla 'plain' czy 'book', używając listy zdefiniowanych ścieżek z `mappings`.

  3. Renderowanie zawartości:
    - Funkcja `renderContent` określa, co powinno być wyrenderowane na podstawie aktualnego URL. Używa `isValidPath` i parametrów z URLa do wyświetlenia odpowiedniego komponentu.

  4. `renderBookDetails` i `renderNotFoundWithAddBookFooter`:
    - To są pomocnicze funkcje renderujące, które zwracają komponenty `CardDetails` lub komponenty informujące o nieznalezieniu strony oraz dodawaniu firmy.

  5. Schemat `paramsSchema`:
    - Jest używany do dodania strukturyzowanych danych do tagu `<Head>` dla SEO, które reprezentują wydarzenie (`Event`) w formacie rozumianym przez wyszukiwarki.

  ### DynamicRoute:

  Twoja funkcja `DynamicRoute` jest de facto komponentem strony w Next.js, który przyjmuje propsy `metaTags` i `bookDetails`:

  - `metaTags` prawdopodobnie zawierają informacje SEO, które zostały przygotowane na podstawie URL i pobranych danych.
  - `bookDetails` zawiera szczegóły aktywności, które są pobierane w `getServerSideProps` jeśli URL pasuje do wzorca 'book' i ma odpowiednią liczbę segmentów.

  Ważne jest, że `getServerSideProps` zwraca te dane jako propsy dla twojego komponentu `DynamicRoute`, dzięki czemu możesz je wykorzystać w renderowanym kontencie.

  W skrócie, cały plik definiuje dynamiczną stronę w Next.js, która jest w stanie obsługiwać różne trasy i renderować różne komponenty w zależności od URL, a także pobierać specyficzne dane na serwerze przed renderowaniem strony dla użytkownika.
*/