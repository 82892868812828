// src/components/CardDetails/index.js
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { FormattedMessage } from 'react-intl';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { Spinner } from 'react-bootstrap';
import { Input } from "reactstrap";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useRouter as useNextRouter } from 'next/router';
// import { useRouter as useNavigationRouter } from 'next/navigation';
import Image from 'next/image';
import PropTypes from 'prop-types';
import Link from 'next/link';
// import { Rating } from 'react-simple-star-rating';
import { 
    FaBook, FaMapMarkerAlt, 
    FaPhone, FaEnvelope, FaGlobe, 
    FaCreditCard, FaMoneyBillWave, 
    FaMobileAlt, FaCar, FaParking, FaKey, 
    FaRegClock, FaTruck, FaStore, FaInfoCircle,
    FaTrashAlt, FaPencilAlt
} from 'react-icons/fa';
import { MdDirectionsBus } from 'react-icons/md';
import { Tooltip as ReactTooltip } from 'react-tooltip';
const LazyRating = React.lazy(() => import('react-simple-star-rating').then(module => ({ default: module.Rating })));
import { useSession } from 'next-auth/react';
import { toast } from 'react-toastify';

import styles from './css/card-details.module.css';

import { IconPaths, ImagePaths, LogoPaths } from '@/constants';
import { getSourceName, usePrevious, createSitemapUrl, changeUrlWithoutReloading, renderStatusIcon } from '@/utils';
import ResizableImage from '@/components/_common/ResizableImage';
import fetchBookOffersById from '@//lib/redux/bookOffersById/ApiActionCreator';
import fetchSimilarBookOffers from '../../lib/redux/similarBookOffers/ApiActionCreator';
import { addOrUpdateRecentlyViewdBookOffers } from '@/lib/redux/recentlyViewedBookOffers/Actions';
import { CardsGrid } from '@/components/CardsGrid';
import Pagination from '@/components/_common/Pagination';
import { AddBookFooter } from '@/components/AddBookFooter';
import { genAltText } from '@/utils';
import { Trans } from '@/utils/intlTranslations';
import { useBreadcrumbs } from '@/contexts/BreadcrumbsContext';
import { useLanguage } from '@/contexts/LanguageContext';
import { Breadcrumbs } from '@/components/_common/Breadcrumbs';
import SelectSortBox from '@/components/_common/SelectSortBox';
import { validPaths } from '@/constants/mappings';
import { useAvailableSoon } from '@/contexts/AvailableSoonContext';
import { NoResultsContent } from '@/components/_common/NoResultsContent';
import { useGeolocation } from '@/contexts/GeolocationContext';
import { OpeningHoursModalBox } from '@/components/OpeningHoursModalBox';
import { DeliveryOptionsModalBox } from '@/components/DeliveryOptionsModalBox';
import { PublicTransitModalBox } from '@/components/PublicTransitModalBox';
import { LocationMap } from '@/components/_common/LocationMap';
import { LocationDetailsModalBox } from '@/components/LocationDetailsModalBox';
import { DeleteConfirmationDialog } from '@/components/_common/DeleteConfirmationDialog';
import { setBookOffersId } from '@/lib/redux/bookOffersSlice';
import Loading from "@/components/_common/Loading/Loading";
import fetchUserBookOffers from '@/lib/redux/userBookOffers/ApiActionCreator';
import { serializeToJson } from '@/helpers/serializeToJson';
import { extractBookInformationDetails } from '@/lib/api/extractBookInformationDetails';
import { useFavorite } from '@/contexts/FavoritesContext';

// import Accordion from '@/components/_common/Accordion';

import debugLog from '@/helpers/debugLog'; 

const CardDetails = ({ bookUrlPathData, bookDetailsData }) => {
    // debugLog('CardDetails bookUrlPathData', 'debug', bookUrlPathData);
    debugLog('CardDetails bookDetailsData' , bookDetailsData);
    const { changeIsShownState } = useAvailableSoon();
    const {locale, userSelectedLanguage} = useLanguage();
    const { data: session } = useSession(); // Pobranie sesji
    const sessionData = useSelector((state) => state.session.user);
    const [loading, setLoaging] = useState(false);
    const [bookInformationAmazonAffiliateInfo, setbookInformationAmazonAffiliateInfo] = useState(null);
    const { favorites, toggleFavorite, isFavorite } = useFavorite();

    // Selektor Reduxa
    const { data: bookOffersByIdData, loading: bookOffersByIdIsLoading } = useSelector((state) => state.bookOffersById);
    const { data: similarBookOffersData, loading: similarBookOffersIsLoading } = useSelector((state) => state.similarBookOffers);
    const { data: recentlyViewedBookOffersData, loading: recentlyViewedBookOffersIsLoading } = useSelector((state) => state.recentlyViewedBookOffers);

    const [cardDetails, setCardDetails] = useState(bookDetailsData);
    const [similarBookOffersOverview, setSimilarBookOffersOverview] = useState([]);
    const [latitudeLongitude, setLatitudeLongitude] = useState(
        {
            center: {
              lat: cardDetails[0]?.location?.latitude ? parseFloat(cardDetails[0].location.latitude): null,
              lng: cardDetails[0]?.location?.longitude ? parseFloat(cardDetails[0].location.longitude): null
            },
            zoom: 14
        }   
    );
    const [bookDetailsMobileMenu, setBookDetailsMobileMenu] =  useState('1');
    const [bookStickyInfoMenu, setStickyInfoMenu] =  useState('1');
    const [coverImage, setCoverImage] = useState(ImagePaths.openBookLargeImg); 
    const [photoCardDetailsIndex, setPhotoCardDetailsIndex] = useState(0); 
    const [isLightboxOpen, setIsLightboxOpen] = useState();
    const [showSpinner, setShowSpinner] = useState(true);
    const [showSpinnerMessage, setShowSpinnerMessage] = useState(false);
    const toggleLightboxOpen = () => {
        setIsLightboxOpen(!isLightboxOpen);
    };
    const cookies = useMemo(() => new Cookies(), []);
    const intl = useIntl();
    const BookOfferSourceIntl = intl.formatMessage({ id: "BookOfferSource" });
    const { setBreadcrumbs } = useBreadcrumbs();
    const dispatch = useDispatch();
    const currentHostname = window.location.hostname;
    const originDomain = `${window.location.protocol}//${window.location.host}`;
    const { coordinates } = useGeolocation(); 
    const [isOpeningHoursModalBoxOpen, setIsOpeningHoursModalBoxOpen] = useState(false);
    const [isDeliveryOptionsModalBoxOpen, setIsDeliveryOptionsModalBoxOpen] = useState(false);
    const [isPublicTransitModalBoxOpen, setIsPublicTransitModalBoxOpen] = useState(false);
    const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
    const searchPhrase = '';
    const [sortState, setSortState] = useState({
        sortField: 'created_at',
        sortDirection: 'desc',
    });
    
    // 
    // Functions Section
    // 
    const deleteBookOffers = useCallback(async (id) => {
        setLoaging(true);
        try {
          const response = await fetch(`/api/user-book-offers/${id}`, {
            method: 'DELETE',
          });
      
          if (!response.ok) {
            setLoaging(false);
            throw new Error('Błąd podczas usuwania lokalizacji');
          }
      
            const result = await response.json();
            console.log(result);
            setLoaging(false);
            toast.success(
                <FormattedMessage
                    id = "ItemDeletedSuccessfully"
                    defaultMessage="Pozycja została pomyślnie usnunięta."
                />
            ) 

            setTimeout(()=> {
                // odświeżenie stanu moj-zbior
                if (session?.user) {
                    dispatch(fetchUserBookOffers(session.user.id, searchPhrase, 1));
                }
                router.push('/moj-zbior'); 
                // router.replace('/moj-zbior');
            }, 4000);
          // Dodatkowe działania, np. aktualizacja UI po usunięciu
        } catch (error) {
            console.error(error);
            setLoaging(false);
            toast.error(
                <FormattedMessage
                    id = "LocationDeletedError"
                    defaultMessage="Przepraszamy, usunięcie pozycji nie powiodło się z powodu błędu!"
                />
            )
        }
    }, [dispatch, router, session]); 

    const handleBookOrderClick = useCallback((url) => {
        // console.log("handleBookOrderClick => ", url)
        if (url) {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }else {
            changeIsShownState(current => !current);
        }
    }, [changeIsShownState]);

    const handleSocialMediaClick = () => {
        // alert("Link nie jest dostępny.");
        console.log("Link nie jest dostępny.");
    };      

    const handleCloseDeleteConfirmationDialog = useCallback(() => {
        setOpenDeleteConfirmationDialog(false);
    }, []);

    const handleConfirmDeleteConfirmationDialog = useCallback(() => {
        // Tutaj logika usuwania elementu
        if ( cardDetails?.length > 0 && cardDetails[0].id) {
            deleteBookOffers(cardDetails[0].id); // changeIsShownState(true);
            // changeIsShownState(true);
        }
        handleCloseDeleteConfirmationDialog();
    }, [cardDetails, deleteBookOffers, handleCloseDeleteConfirmationDialog]);

    const handleOpenDeleteConfirmationDialog = useCallback(() => {
        setOpenDeleteConfirmationDialog(true); 
    }, []);

    const handleReturnHomePageClick = useCallback(() => {
        router.push("/");
    }, [router]);

    const handleEditBookOffers = useCallback((card, lang) => {  
        if (card?.id) {
            dispatch(setBookOffersId(card.id));
            router.push('/moj-zbior/edytuj-ksiazke'); // changeIsShownState(true);
            // changeIsShownState(true); 
        }
    }, [router, dispatch]); 

    const getLocationAddress = useCallback((card, maxLength = 50) => {
        const parts = [
            card.street,
            card.city,
            card.state,
            card.postcode,
            card.country
        ];
        
        // Filtruje puste, null i undefined wartości
        const filteredParts = parts.filter(part => part);

        // Łączy części adresu
        const address = filteredParts.join(', ');

        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        if (filteredParts.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak danych teleadresowych'); 
        }

        // Skraca adres, jeśli jest dłuższy niż maxLength
        return address.length > maxLength ? address.substring(0, maxLength) + '...' : address;
    }, []); 

    const getLocationEmail = useCallback((card) => {
        return card.email || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak adresu email');
    }, []); 

    const getLocationPhone = useCallback((card) => {
        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        return card.phone || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak numeru telefonu');
    }, []); 

    const getLocationName = useCallback((card, maxLength = 30) => {
        // Sprawdza, czy pozostały jakiekolwiek części nazwy lokalizacji; jeśli nie, zwraca alternatywny tekst
        if (card.name.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak nazwy lokalizacji'); 
        }
        return card.name.length > maxLength ? card.name.substring(0, maxLength) + '...' : card.name;
    }, []); 

    const getLocationWww = useCallback((card) => {
        // Sprawdza, czy pozostały jakiekolwiek części adresu; jeśli nie, zwraca alternatywny tekst
        return card.www || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'Brak adresu strony www');
    }, []); 

    const getBookAuthors = useCallback((card) => {
        return card.authors || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych');
    }, []); 

    const getBookCondition = useCallback((card, lang) => {
        if (card === '' || card === null || card === undefined) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych');
        }else {
            card[`condition_${lang}`] || <div dangerouslySetInnerHTML={{__html: card }}/>
        }
    }, []); 

    const getAttributes = (attributes) => {
        const items = [
            {name: 'wheelchair_accessible', icon: IconPaths.wheelchairAccessibleIcon, textId:'wheelchairAccessible', textDefaultMessage:'Dla niepełnosprawnych'},
            {name: 'lgbtq_friendly', icon: IconPaths.lgbtqFriendlyIcon, textId:'lgbtqFriendly', textDefaultMessage:'Przyjazne LGBT+'},
            {name: 'pet_friendly', icon: IconPaths.petFriendlyIcon, textId:'petFriendly', textDefaultMessage:'Przyjazne dla zwierząt'},
            {name: 'senior_friendly', icon: IconPaths.seniorFriendlyIcon, textId:'seniorFriendly', textDefaultMessage:'Dla starszych'},
            {name: 'kids_friendly', icon: IconPaths.kidsFriendlyIcon, textId:'kidsFriendly', textDefaultMessage:'Dla dzieci'},
            {name: 'eighteen_plus_only', icon: IconPaths.eighteenPlusOnlyIcon, textId:'eighteenPlusOnly', textDefaultMessage:'18+'},
            {name: 'free', icon: IconPaths.freeIcon, textId:'free', textDefaultMessage:'Bezpłatne+'}
        ];
        if (attributes.length > 0) {
            return (
                <div className={styles["cdd--sec-l-atr-a-l"]}>
                    {items.map((item) => {
                        if (attributes.filter(attr => attr[item.name]).length > 0) {
                            return (
                                <div key={item.name} className={styles["cdd--sec-l-atr-a-i"]}>
                                    <Image
                                        src={item.icon}
                                        alt={item.name}
                                        width={24} height={24}
                                        className={styles["cdd--sec-l-atr-a-i-ico"]}
                                    />
                                    { 
                                        <FormattedMessage
                                            id = {item.textId}
                                            defaultMessage={item.textDefaultMessage}
                                        />
                                    }
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
    }

    const getCoverType = useCallback((card) => {
        return card.cover_type || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych');
    }, []); 

    const getFormat = useCallback((card) => {
        return card.format || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych');
    }, []);

    const getISBN = useCallback((card) => {
        if (card) {
            return card.isbn || card.isbn_13 || card.isbn_10 || card.ean || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych');
        }
    }, []); 

    const getLatitudeLongitude = useCallback((card) => {
        card?.latitude && card?.longitude 
        ? 
        setLatitudeLongitude(
            {
                center: {
                  lat: parseFloat(card.latitude),
                  lng: parseFloat(card.longitude)
                },
                zoom: 14
            }
        )
        :
        setLatitudeLongitude(
            {
                center: {
                  lat: null,
                  lng: null
                },
                zoom: 5
            }
        )
    }, []);  

    const getLowestPricevariants = useCallback((card) => {
        // const lowestPrice = pricevariants.reduce((lowest, variant) => {
        //   const variantPrice = parseFloat(variant.price);
        //   return variantPrice < lowest ? variantPrice : lowest;
        // }, Infinity);
      
        // return lowestPrice.toFixed(2) + " zł";
        return card.price.toFixed(2) + " zł";
    }, []);

    const getPageCount = useCallback((card) => {
        return card.page_count || React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych');
    }, []); 

    const getShortDescription = useCallback((card, lang) => {
        return card[`lead_${lang}`] || card.short_description;
    }, []); 

    const getPublisher = useCallback((data) => {
        return data.publisher || '';
    }, []); 

    const getTagCategoryList = useCallback((categoriesTags, emptyMessage) => {
        // Sprawdza, czy categoriesTags jest pusty lub null
        if (!categoriesTags || categoriesTags.trim() === '') {
            return (
                <div className={styles["cdd--sec-l-t-t"]}>
                    <div className={styles["cdd--sec-l-t-t-t"]}>
                        {emptyMessage}
                    </div>
                </div>
            )
        }

        // Przekształcenie stringa na tablicę, oddzielając kategorie przecinkami
        const categoryArray = typeof categoriesTags === 'string' ? categoriesTags.split(',').map(cat => cat.trim()) : [categoriesTags];
    
        // Sprawdzenie, czy istnieją jakiekolwiek kategorie
        if (categoryArray.length > 0 && categoryArray[0] !== "") {
            return (
                <div className={styles["cdd--sec-l-t-t"]}>
                    {categoryArray.map((catName, index) => (
                        <div key={index} className={styles["cdd--sec-l-t-t-t"]}>
                            {/* Tutaj możesz wykonać wyszukiwanie lub mapowanie nazwy kategorii na odpowiedni obiekt, jeśli jest to wymagane */}
                            {catName}
                        </div>
                    ))}
                </div>
            );
        } 
    }, []);     

    const getTitle = useCallback((cardDetails, lang) => {
        const firstCardDetail = cardDetails && cardDetails[0];
        return firstCardDetail ? (firstCardDetail[`title_${lang}`] || firstCardDetail.title) : '';
    }, []);

    const getYear = useCallback((data) => {
        return data.year || '';
    }, []); 
    // 
    // END Functions Section
    // 

    // 
    // Components Section
    // 
    const BookBasicInfo  = ({cardDetails}) => {
        return (
            <div className={styles["cdd--sec-l-o"]}>
                <div className={styles["cdd--sec-l-o-o"]}>
                    {/* Tytuł */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "Title"
                                defaultMessage="Tytuł"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getTitle(cardDetails, locale)}
                            </h2>
                        </div>
                    </div>

                    {/* Autorzy */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "Authors"
                                defaultMessage="Autorzy"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getBookAuthors(cardDetails[0])}
                            </h2>
                        </div>
                    </div>

                    {/* Rok wydania */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "Year"
                                defaultMessage="Rok wydania"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getYear(cardDetails[0])}
                            </h2>
                        </div>
                    </div>

                    {/* Wydawca */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "Publisher"
                                defaultMessage="Wydawca"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getPublisher(cardDetails[0])}
                            </h2>
                        </div>
                    </div>
                    
                    {/* ISBN */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "ISBN"
                                defaultMessage="ISBN"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getISBN(cardDetails[0])}
                            </h2>
                        </div>
                    </div>
                    
                    {/* Okładka */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "CoverType"
                                defaultMessage="Oprawa"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getCoverType(cardDetails[0])}
                            </h2>
                        </div>
                    </div>
                    
                    {/* Format */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "Format"
                                defaultMessage="format"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getFormat(cardDetails[0])}
                            </h2>
                        </div>
                    </div>

                    {/* Liczba stron" */}
                    <div className={styles["cdd--sec-l-o-o-o"]}>
                        <div className={styles["cdd--sec-l-o-o-o-h"]}>
                            <FormattedMessage
                                id = "PageCount"
                                defaultMessage="Liczba stron"
                            />:
                        </div>
                        <div className={styles["cdd--sec-l-o-o-o-t"]}>
                            <h2>
                                {getPageCount(cardDetails[0])}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const BookAdditionalInfo = ({card}) => {
        const [showMoreAdditionalInfo, setShowMoreAdditionalInfo] = useState(false);
        const intl = useIntl();
        const readMoreIntl = intl.formatMessage({ id: "ReadMore" });
        const readLessIntl = intl.formatMessage({ id: "ReadLess" });

        return (
            card?.additional_info ?
                <>
                    <div className={styles["cdd--sec-l-ed"]}>
                        <div className={styles["cdd--sec-l-ed-h"]}>
                            <h2>
                                <FormattedMessage
                                    id = "AdditionalInformation"
                                    defaultMessage="Dodatkowe informacje"
                                />
                            </h2>
                        </div>
                        <div className={styles["cdd--sec-l-ed-h-t"]}>  
                        {   
                            card?.additional_info?.length > 450 ?
                                !showMoreAdditionalInfo ? 
                                    <div dangerouslySetInnerHTML={{__html: card.additional_info.substring(0,450).concat("...") }} />
                                :
                                    <div dangerouslySetInnerHTML={{__html: card.additional_info }}/>
                            :
                                <div dangerouslySetInnerHTML={{__html: card.additional_info }}/>
                        }
                        {   
                            card?.additional_info?.length > 450 ?
                                <div className={styles["cdd--sec-l-ed-h-t-link"]} onClick={() => setShowMoreAdditionalInfo(!showMoreAdditionalInfo)}>
                                    {!showMoreAdditionalInfo ? "("+readMoreIntl+")" : "("+readLessIntl+")"}
                                </div>
                            : ''
                        }
                        </div>
                        <div className={styles["cdd--sec-l-ed-h-ts"]}>
                            <FormattedMessage
                                id = "BookOfferSource"
                                defaultMessage="Źródło"
                            />
                            : 
                            { 
                                ' ' + getSourceName(card.source)
                            } 
                            <sup style={{fontSize:'14px'}}>*</sup>
                            <br />
                            * <span style={{fontSize: '10px', fontWeight: 'normal', wordBreak: 'break-all'}}>
                                źródło tymczasowe do czasu implementacji pobierania faktycznego źródła aktualizacji danych
                            </span>
                        </div>
                    </div>
                </>
            : 
                <div className={styles["cdd--sec-l-ed"]}>
                    <div className={styles["cdd--sec-l-ed-h"]}>
                        <h2>
                            <FormattedMessage
                                id = "AdditionalInformation"
                                defaultMessage="Dodatkowe informacje"
                            />
                        </h2>
                    </div>
                    <div className={styles["cdd--sec-l-ed-h-t"]} style={{alignSelf: 'flex-start'}}> 
                        { React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') }
                    </div>
                </div>
        )
    }

    const BookAmazonAffiliate = ({ amazonAffiliateInfo }) => {
        const openInNewTab = (url) => {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }; 
        return (
            <div onClick={() => openInNewTab(amazonAffiliateInfo.item_url)} className={styles["book-container-amazon-affiliate"]}>
                <Image 
                    // src={imageUrl} 
                    src={IconPaths.amazonIcon}
                    alt={genAltText(IconPaths.amazonIcon)} 
                    width={'100'}
                    height={'40'}
                    className={styles["book-container-amazon-affiliate-icon"]} 
                    placeholder="blur"
                    blurDataURL={ImagePaths.amazonBlurImg}
                />
            </div>
        );
    };

    const BookCondition = ({card}) => {
        return (
            <>
                <div className={styles["cdd--sec-l-o-c"]}>
                    {/* Stan książki */}
                    <div className={styles["cdd--sec-l-o-c-sec"]}>
                        <div className={styles["cdd--sec-l-c"]}>
                            <div className={styles["cdd--sec-l-c-h"]}>
                                <h2>
                                    <FormattedMessage
                                        id = "BookCondition"
                                        defaultMessage="Stan książki"
                                    />
                                </h2>
                            </div>
                            <div className={styles["cdd--sec-l-c-l"]}>
                                {getBookCondition(card.condition, locale)}
                            </div>
                        </div>
                    </div>

                    {/* Opis stanu książki */}
                    <div className={styles["cdd--sec-l-o-c-sec"]}>
                        <div className={styles["cdd--sec-l-c"]}>
                            <div className={styles["cdd--sec-l-c-h"]}>
                                <h2>
                                    <FormattedMessage
                                        id = "BookConditionDesc"
                                        defaultMessage="Opis stanu książki"
                                    />
                                </h2>
                            </div>
                            <div className={styles["cdd--sec-l-c-l"]}>
                                {getBookCondition(card.condition_description, locale)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const BookDescription = ({card}) => {
        console.log("BookDescription", card.description)
        const [showMoreDescription, setShowMoreDescription] = useState(false);
        const intl = useIntl();
        const readMoreIntl = intl.formatMessage({ id: "ReadMore" });
        const readLessIntl = intl.formatMessage({ id: "ReadLess" });

        return (
            card?.description ?
                <>
                    <div className={styles["cdd--sec-l-ed"]}>
                        <div className={styles["cdd--sec-l-ed-h"]}>
                            <h2>
                                <FormattedMessage
                                    id = "Description"
                                    defaultMessage="Opis"
                                />
                            </h2>
                        </div>
                        <div className={styles["cdd--sec-l-ed-h-t"]}>  
                        {   
                            card?.description?.length > 450 ?
                                !showMoreDescription ? 
                                    <div dangerouslySetInnerHTML={{__html: card.description.substring(0,450).concat("...") }} />
                                :
                                    <div dangerouslySetInnerHTML={{__html: card.description }}/>
                            :
                                <div dangerouslySetInnerHTML={{__html: card.description }}/>
                        }
                        {   
                            card?.description?.length > 450 ?
                                <div className={styles["cdd--sec-l-ed-h-t-link"]} onClick={() => setShowMoreDescription(!showMoreDescription)}>
                                    {!showMoreDescription ? "("+readMoreIntl+")" : "("+readLessIntl+")"}
                                </div>
                            : ''
                        }
                        </div>
                        <div className={styles["cdd--sec-l-ed-h-ts"]}>
                            <FormattedMessage
                                id = "BookOfferSource"
                                defaultMessage="Źródło"
                            />
                            : 
                            { 
                                ' ' + getSourceName(card.source)
                            } 
                            <sup style={{fontSize:'14px'}}>*</sup>
                            <br />
                            * <span style={{fontSize: '10px', fontWeight: 'normal', wordBreak: 'break-all'}}>
                                źródło tymczasowe do czasu implementacji pobierania faktycznego źródła aktualizacji danych
                            </span>
                        </div>
                    </div>
                </>
            : 
                <div className={styles["cdd--sec-l-ed"]}>
                    <div className={styles["cdd--sec-l-ed-h"]}>
                        <h2>
                            <FormattedMessage
                                id = "Description"
                                defaultMessage="Opis"
                            />
                        </h2>
                    </div>
                    <div className={styles["cdd--sec-l-ed-h-t"]} style={{alignSelf: 'flex-start'}}> 
                        { React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak danych') }
                    </div>
                </div>
        )
    }

    const BookEditRemoveBtn = ({ card }) => { 
        return (
            <>
                <div className={styles["edit-view-btn-box-sec"]}>
                    {/* Edytuj pozycję */}
                    <div onClick={()=> handleEditBookOffers(card, locale)} >
                        <FaPencilAlt 
                            className={styles["edit-view-btn-box-sec-ico"]} 
                            data-tooltip-id="my-tooltip-edit" 
                            data-tooltip-html={'Edytuj pozycję'}
                        />
                       
                    </div>
                    {/* Usuń pozycję */}
                    <div onClick={()=> handleOpenDeleteConfirmationDialog()} >
                        <FaTrashAlt 
                            className={styles["edit-view-btn-box-sec-ico"]} 
                            data-tooltip-id="my-tooltip-remove" 
                            data-tooltip-html={'Usuń pozycję'}
                        />
                    </div>
                </div>
                {/* ReactTooltip */}
                <ReactTooltip 
                    key={new Date() + 'my-tooltip-edit'}
                    id="my-tooltip-edit" 
                    place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                    className="custom-tooltip" 
                    effect="solid"
                />
                {/* ReactTooltip */}
                <ReactTooltip 
                    key={new Date() + 'my-tooltip-remove'}
                    id="my-tooltip-remove" 
                    place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                    className="custom-tooltip" 
                    effect="solid"
                />
            </>
        );
    };

    // Użyj ref, aby zapisać referencję do div
    let divBookImagesBeltRef = useRef(null);
    const BookImagesBelt = ({bookOffersImages}) => {
        // debugLog('CardDetails BookImagesBelt', 'debug', bookOffersImages);
        const [photoBookImagesBeltIndex, setPhotoBookImagesBeltIndex] = useState(0); 
        const [isLightboxOpen, setIsLightboxOpen] = useState();
        const toggleLightboxOpen = () => {
            setIsLightboxOpen(!isLightboxOpen);
        };
        // const onImageClick = (imageUrl) => {
        //     if (imageUrl === coverImage) {
        //         return;
        //         }
        //     setCoverImage(imageUrl);
        // }

        return (
            cardDetails[0]?.bookOffersImages?.length > 1 ?
                <>
                    <div className={styles["cdd--sec-l-i-a-i"]}>
                        <div className={styles["cdd--sec-l-i-a-i-arrow-l"]} onClick={() => divBookImagesBeltRef.current.scrollLeft -= 150}>
                            <Image 
                                src={IconPaths.leftArrowIcon} 
                                alt={genAltText(IconPaths.leftArrowIcon)} 
                                width={40} height={40} 
                                className={styles["cdd--sec-l-i-a-i-arrow-ico"]} 
                            />
                        </div>
                        <div ref={divBookImagesBeltRef} id="cdd--sec-l-i-a-i-sec"  className={styles["cdd--sec-l-i-a-i-sec"]}>
                            {
                                bookOffersImages.filter(image => image.highlighted).map((image, index) => (
                                    <Image
                                        src={image.highlighted}
                                        alt={image.highlighted}
                                        width={450} height={450}
                                        className={styles["cdd--sec-l-i-a-i-img"]}
                                        key={image.id+index}
                                        // onClick={() => onImageClick(image.highlighted)}
                                        onClick={() => toggleLightboxOpen()}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ))
                            }
                            {
                                isLightboxOpen && (
                                    <Lightbox
                                        mainSrc={bookOffersImages[photoBookImagesBeltIndex].highlighted}
                                        nextSrc={bookOffersImages[(photoBookImagesBeltIndex + 1) % bookOffersImages.length].highlighted}
                                        prevSrc={bookOffersImages[(photoBookImagesBeltIndex + bookOffersImages.length - 1) % bookOffersImages.length].highlighted}
                                        onCloseRequest={() => setIsLightboxOpen(false)}
                                        onMovePrevRequest={() => setPhotoBookImagesBeltIndex((photoBookImagesBeltIndex + bookOffersImages.length - 1) % bookOffersImages.length)}
                                        onMoveNextRequest={() =>
                                            setPhotoBookImagesBeltIndex((photoBookImagesBeltIndex + 1) % bookOffersImages.length)
                                        }
                                        imageTitle={getTitle(cardDetails, locale)}
                                        imageCaption={BookOfferSourceIntl + ': ' + getSourceName(cardDetails[0].picture_source)}
                                    />
                                )
                            }
                        </div>
                        <div className={styles["cdd--sec-l-i-a-i-arrow-r"]} onClick={() => divBookImagesBeltRef.current.scrollLeft += 150}>
                            <Image 
                                src={IconPaths.rightArrowIcon} 
                                alt={genAltText(IconPaths.rightArrowIcon)} 
                                width={40} 
                                height={40} 
                                className={styles["cdd--sec-l-i-a-i-arrow-ico"]} 
                            />
                        </div>
                    </div>
                </>
            : ''
        )
    }

    const BookMobileMenu =() => {
        const bookDetailsMobileMenuMap = {
            '1': styles['cdd--mob-m-t-atv-l'],
            '2': styles['cdd--mob-m-t-atv-m'],
            '3': styles['cdd--mob-m-t-atv-r']
        };
        
        const activeClass = bookDetailsMobileMenuMap[bookDetailsMobileMenu] || '';
      
        return (
            <div className={styles["cdd--mob-menu"]}>
                <div className={styles["cdd--mob-m"]}>
                    <div onClick={() => {setBookDetailsMobileMenu('1')}} className={`${styles['cdd--mob-m-t']} ${bookDetailsMobileMenu === '1' ? activeClass : ''}`}>
                        <FormattedMessage
                            id = "Location"
                            defaultMessage="Lokalizacja"
                        />
                    </div>
                    <div onClick={() => {setBookDetailsMobileMenu('2')}} className={`${styles['cdd--mob-m-t']} ${bookDetailsMobileMenu === '2' ? activeClass : ''}`}>
                        <FormattedMessage
                            id = "BookReviews"
                            defaultMessage="Opinie"
                        />
                    </div>
                    <div onClick={() => {setBookDetailsMobileMenu('3')}} className={`${styles['cdd--mob-m-t']} ${bookDetailsMobileMenu === '3' ? activeClass : ''}`}>
                        <FormattedMessage
                            id = "AdditionalInfo"
                            defaultMessage="Pozostałe"
                        />
                    </div>
                </div>
            </div>
        )
    }

    const BookStickyInfoMenu =() => {
        const bookStickyInfoMenuMap = {
            '1': styles['cdd--sticky-m-t-atv-l'],
            '2': styles['cdd--sticky-m-t-atv-m'],
            '3': styles['cdd--sticky-m-t-atv-r']
        };
        
        const activeClass = bookStickyInfoMenuMap[bookStickyInfoMenu] || '';
      
        return (
            <div className={styles["cdd--sticky-menu"]}>
                <div className={styles["cdd--sticky-m"]}>
                    <div onClick={() => {setStickyInfoMenu('1')}} className={`${styles['cdd--sticky-m-t']} ${bookStickyInfoMenu === '1' ? activeClass : ''}`}>
                        <FormattedMessage
                            id = "Contact"
                            defaultMessage="Kontakt"
                        />
                    </div>
                    <div onClick={() => {setStickyInfoMenu('2')}} className={`${styles['cdd--sticky-m-t']} ${bookStickyInfoMenu === '2' ? activeClass : ''}`}>
                        <FormattedMessage
                            id = "Payments"
                            defaultMessage="Płatności"
                        />
                    </div>
                    <div onClick={() => {setStickyInfoMenu('3')}} className={`${styles['cdd--sticky-m-t']} ${bookStickyInfoMenu === '3' ? activeClass : ''}`}>
                        <FormattedMessage
                            id = "Parking" 
                            defaultMessage="Parking" 
                        />
                    </div>
                </div>
            </div>
        )
    }

    const BookOtherData = ({location}) => {
        return (
            ''
        )
    }

    const BookReviews = ({cardDetails}) => {
        const [bookReviews, setBookReviews] = useState([]);
        const [reviewComment, setReviewComment] = useState("");
        const [ratingTotal, setRatingTotal] = useState(0);
        const [ratingComment, setRatingComment] = useState(0);
        const intl = useIntl();
        const [numberReviews, setNumberReviews] = useState(0 + ' ' + intl.formatMessage({ id: "opinions" }));
        const [showMoreComment, setShowMoreComment] = useState({}); 
        const [selectedSortOption, setSelectedSortOption] = useState('latest');
        const sortOptions = [
            { value: "byTitleFromAtoZ", translationId: "byTitleFromAtoZ", defaultMessage: "wg tytułu od A do Z", sortField: 'title', sortOrder: 'asc' },
            { value: "byTitleFromZtoA", translationId: "byTitleFromZtoA", defaultMessage: "wg tytułu od Z do A", sortField: 'title', sortOrder: 'desc' },
            { value: "lastAdded", translationId: "lastAdded", defaultMessage: "ostatnio dodane", sortField: 'created_at', sortOrder: 'desc' },
            { value: "laterAdded", translationId: "laterAdded", defaultMessage: "później dodane", sortField: 'created_at', sortOrder: 'asc' }
        ]; 

        // 
        // Pagination
        // 
        // Ustawienia paginacji
        const [currentPage, setCurrentPage] = useState(1);
        const recordsPerPage = 6; // Liczba rekordów na stronę
        const nPages = Math.ceil(bookReviews.length / recordsPerPage);

        // Obliczanie zakresu danych do wyświetlenia
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = bookReviews.slice(indexOfFirstRecord, indexOfLastRecord);
        // 
        // END Pagination
        // 

        const formatReviewPublishDate = (dateString) => {
            const dateObject = new Date(dateString); 
            return dateObject.toLocaleDateString("pl-PL", { 
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
        }

        // Optinal callback functions
        const onPointerEnter = () => debugLog('CardDetails onPointerEnter Enter', 'debug', '')
        const onPointerLeave = () => debugLog('CardDetails onPointerLeave Leave', 'debug', '')
        const onPointerMove = (value, index) => debugLog('CardDetails onPointerMove', 'debug', value + "|" + index)

        const handleRatingComment = (rate) => {
            // debugLog('CardDetails handleRatingComment', 'debug', rate);
            setRatingComment(rate);
        }

        const handleSelectSortBoxChange = event => {
            const value = event.target.value;
            setSelectedSortOption(value); // Aktualizuj stan z wybraną opcją
    
            const selectedSortOption = sortOptions.find(option => option.value === value);
            if (selectedSortOption) {
                sortBookReviews(bookReviews, selectedSortOption.sortField, selectedSortOption.sortOrder);
            }
        };
        
        const onReviewCommentChange = (e) => {
            // debugLog('CardDetails onReviewCommentChange', 'debug', e.target.value);
            setReviewComment(e.target.value);
        };
        
        const sortBookReviews = (array, sortValue, order) => {
            let newArray = [...array];
            newArray.sort((a, b) => {
                if (order === "asc") {
                    return a[sortValue] > b[sortValue] ? 1 : -1;
                } 
                if (order === "desc") {
                    return b[sortValue] > a[sortValue] ? 1 : -1;
                }
            });
            setBookReviews(newArray);
        }

        const BookReviewUserComment = ({userComments}) => { 
            const intl = useIntl(); 
            const readMoreIntl = intl.formatMessage({ id: "ReadMore" });
            const readLessIntl = intl.formatMessage({ id: "ReadLess" });
            
            return (
                <div className={styles["cdd--sec-l-r-rp-ur"]}>
                    {
                        userComments?.length > 0 ?
                            userComments.map((review, idx) => {
                                return ( 
                                    <div key={idx} className={styles["cdd--sec-l-r-rp-ur-box"]}>
                                        <div className={styles["cdd--sec-l-r-rp-ur-box-h"]}>
                                            <div className={styles["cdd--sec-l-r-rp-ur-box-h-pd"]}>
                                                <div className={styles["cdd--sec-l-r-rp-ur-box-h-pd-pr"]}>
                                                    <Image 
                                                        src={review.kolomnieUser.picture ? review.kolomnieUser.picture : IconPaths.navbarProfileIcon} 
                                                        alt={genAltText(IconPaths.navbarProfileIcon)} 
                                                        width={42} height={42} 
                                                        className={styles["cdd--sec-l-r-rp-ur-box-h-pd-pr-ico"]} 
                                                    />
                                                    <div className={styles["cdd--sec-l-r-rp-ur-box-h-pd-pr-t"]}>
                                                        {review.kolomnieUser.first_name}
                                                        {' '}
                                                        {review.kolomnieUser.last_name}
                                                    </div>
                                                </div>
                                                <div className={`${styles['cdd--sec-l-r-rp-ur-box-h-pd-rv']} ${styles['cdd--sec-l-r-rp-ur-box-h-pd-rv-s']}`}>
                                                    <React.Suspense fallback={<div>Loading...</div>}>
                                                        <LazyRating
                                                            initialValue={review.rating}
                                                            allowFraction={false}
                                                            size={25}
                                                            readonly={true}
                                                        />
                                                    </React.Suspense>
                                                    <div style={{marginTop: '2px'}}>
                                                        {review.rating}/5
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles["cdd--sec-l-r-rp-ur-box-h-pd"]}>
                                                <div className={styles["cdd--sec-l-r-rp-ur-box-h-pd-p"]}>
                                                    <FormattedMessage
                                                        id = "Published"
                                                        defaultMessage="Wstawiono"
                                                    />:{' '}
                                                    {formatReviewPublishDate(review.created_at)}
                                                </div>
                                                {/* width={28.327} */}
                                                <Image 
                                                    src={IconPaths.kolomnieReviewIcon} 
                                                    alt={genAltText(IconPaths.kolomnieReviewIcon)} 
                                                    width={22} height={26} 
                                                    className={styles["cdd--sec-l-r-rp-ur-box-h-pd-ico"]} 
                                                />
                                            </div>
                                        </div>
                                        <div className={styles["cdd--sec-l-r-rp-ur-box-t"]}>
                                            {    
                                                review.comment.length > 255 ?
                                                    !showMoreComment[review.id] ? 
                                                        <div dangerouslySetInnerHTML={{__html: review.comment.substring(0,250).concat("...") }} />
                                                    :
                                                        <div dangerouslySetInnerHTML={{__html: review.comment }}/>
                                                :
                                                    <div dangerouslySetInnerHTML={{__html: review.comment }}/>
                                            }
                                            { 
                                                review.comment.length > 255 ?
                                                    <div className={styles["cdd--sec-l-ed-h-t-link"]} onClick={() => setShowMoreComment({...showMoreComment, [review.id]: !showMoreComment[review.id]})}>
                                                        {!showMoreComment[review.id] ? "("+readMoreIntl+")" : "("+readLessIntl+")"}
                                                    </div>
                                                : ''
                                            }
                                        </div>
                                        <div className={styles["cdd--sec-l-r-rp-ur-box-i"]}>
                                            {/* {<BookOffersReviewImagesSlideShow bookOffersReviewImages={review.reviewImages} eventReviewId={review.id} />} */}
                                        </div>
                                    </div>
                                )
                            })
                        :
                            <div className={styles["cdd--sec-l-r-rp-ur-empty"]}>
                                <h2>
                                    <FormattedMessage
                                        id = "BeFirstToAddReview"
                                        defaultMessage="Dodaj opinię jako pierwszy"
                                    />
                                </h2>
                                <Image 
                                    src={ImagePaths.reviewEmptyImg} 
                                    alt={genAltText(ImagePaths.reviewEmptyImg)} 
                                    width={270} height={270} 
                                    className={styles["cdd--sec-l-r-rp-ur-empty-img"]} 
                                />
                            </div>
                    }
                </div>
            )
        }

        useEffect(() => {
            if ( cardDetails[0]?.bookReviews?.length > 0 ){
                let newArray = cardDetails[0].bookReviews;
                sortBookReviews(newArray, 'created_at', "desc");
                cardDetails[0].bookReviews.map(review => {
                    setShowMoreComment({
                        ...showMoreComment, 
                        [review.id]: false
                    })
                })
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [cardDetails])

        useEffect(() => {
            if ( cardDetails[0]?.bookReviews?.length > 0 ){
                let sum = 0;
                for (let i = 0; i < cardDetails[0].bookReviews.length; i++) {
                    sum += Number(cardDetails[0].bookReviews[i].rating);
                }
                let average = sum / cardDetails[0].bookReviews.length;
                setRatingTotal((Math.round(average * 10) / 10).toFixed(1));
 
                if (cardDetails[0].bookReviews.length === 0) {
                    setNumberReviews(cardDetails[0].bookReviews.length + ' ' + intl.formatMessage({ id: "opinions" }));
                }else if (cardDetails[0].bookReviews.length === 1) {
                    setNumberReviews(cardDetails[0].bookReviews.length + ' ' + intl.formatMessage({ id: "opinion" }));
                }else if ([2,3,4].includes(cardDetails[0].bookReviews.length)) {
                    setNumberReviews(cardDetails[0].bookReviews.length + ' ' + intl.formatMessage({ id: "opinions_" }));
                }else {
                    setNumberReviews(cardDetails[0].bookReviews.length + ' ' + intl.formatMessage({ id: "opinions" }));
                }
            }
        }, [ratingTotal, intl, cardDetails])
        
        return (
            // cardDetails != undefined && cardDetails && cardDetails.length > 0 
            cardDetails?.length > 0 ?
                <>
                    {/* Opinie */}
                    <div className={styles["cdd--sec-l-r"]} >
                        {/* Panel z komentarzami */}
                        <div className={styles["cdd--sec-l-r-rp"]} >
                            {/* Contner */}
                            <div className={styles["cdd--sec-l-r-rp-c"]}>
                                {/* Inner */}
                                <div className={styles["cdd--sec-l-r-rp-c-i"]}>
                                    {/* Opinie */}
                                    <div className={styles["cdd--sec-l-r-rp-c-i-h"]} >
                                        <h2>
                                            <FormattedMessage
                                                id = "BookReviews"
                                                defaultMessage="Opinie"
                                            />
                                        </h2>
                                    </div>
                                    {/* Opinie */}
                                    <div className={styles["cdd--sec-l-r-rp-c-i-rs"]} >
                                        <h2>
                                            {ratingTotal}/5
                                        </h2>
                                        <React.Suspense fallback={<div>Loading...</div>}>
                                            <LazyRating
                                                initialValue={ratingTotal}
                                                allowFraction={true}
                                                size={25}
                                                readonly={true}
                                            />
                                        </React.Suspense>
                                        <div>
                                            {numberReviews}
                                        </div>
                                    </div>
                                </div>
                                {/* SortReviews */}
                                {
                                    bookReviews
                                    && bookReviews.length > 0 ?
                                        <SelectSortBox 
                                            selectedOption={selectedSortOption} 
                                            onSortChange={handleSelectSortBoxChange} 
                                            sortOptions={sortOptions} 
                                        />  
                                    : ''
                                }
                            </div>
                            {/* BookReviewUserComment */}
                            <BookReviewUserComment userComments={currentRecords}/>
                        </div>
                        {
                            bookReviews?.length > recordsPerPage +1 ?
                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            : ''
                        }
                        {/* LeaveReview */}
                        <div className={styles["cdd--sec-l-r-lr"]}>
                            <div className={styles["cdd--sec-l-r-lr-h"]}>
                                <h2>
                                    <FormattedMessage
                                        id = "LeaveReview"
                                        defaultMessage="Wystaw opinie"
                                    />
                                </h2>
                            </div>
                            <Input
                                className={styles["cdd--sec-l-r-lr-fc"]}
                                type="textarea"
                                placeholder={intl.formatMessage({ id: "WriteReview" })}
                                value={reviewComment}
                                onChange={onReviewCommentChange}
                                rows="5"
                            />
                            <div className={styles["cdd--sec-l-r-lr-fc-d"]}>
                                <div className={styles["cdd--sec-l-r-lr-fc-d-l"]} onClick={()=> changeIsShownState(true)} style={{ cursor: 'pointer' }}>
                                    {/* <Image 
                                        src={IconPaths.addPhotoIcon} 
                                        alt={genAltText(IconPaths.addPhotoIcon)} 
                                        width={18} height={18} 
                                        className={styles["cdd--sec-l-r-lr-fc-d-l-ico"]} 
                                    />
                                    <div className={styles["cdd--sec-l-r-lr-fc-d-l-t"]}>
                                        <FormattedMessage
                                            id = "AddPhotos"
                                            defaultMessage="Dodaj zdjęcia"
                                        />
                                    </div> */}
                                </div>
                                <div className={styles["cdd--sec-l-r-lr-fc-d-r"]}>
                                    <div className={styles["cdd--sec-l-r-lr-fc-d-r-s"]} itemProp="starRating">
                                        <h3 itemProp="ratingValue">
                                            {ratingComment}/5
                                        </h3>
                                        <React.Suspense fallback={<div>Loading...</div>}>
                                            <LazyRating
                                                onClick={handleRatingComment}
                                                onPointerEnter={onPointerEnter}
                                                onPointerLeave={onPointerLeave}
                                                onPointerMove={onPointerMove}
                                                /* Available Props */
                                                initialValue={0}
                                                allowFraction={true}
                                                size={25}
                                            />
                                        </React.Suspense>
                                    </div>
                                    <div className={styles["cdd--sec-l-r-lr-fc-d-r-btn"]} onClick={()=> changeIsShownState(true)} style={{ cursor: 'pointer' }}>
                                        <Image 
                                            src={IconPaths.addReviewIcon} 
                                            alt={genAltText(IconPaths.addReviewIcon)} 
                                            width={19} height={18} 
                                            className={styles["cdd--sec-l-r-lr-fc-d-r-btn-ico"]} 
                                        />
                                        <div className={styles["cdd--sec-l-r-lr-fc-d-r-btn-t"]}>
                                            <FormattedMessage
                                                id = "AddReview"
                                                defaultMessage="Dodaj opinię"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            : ''
        )
    }

    // Użyj ref, aby zapisać referencję do div
    let divbookOffersReviewImagesRef = useRef(null);
    const BookOffersReviewImagesSlideShow = ({bookOffersReviewImages, bookOffersReviewId}) => {
        // debugLog('CardDetails BookOffersReviewImagesSlideShow', 'debug', bookOffersReviewImages);
        const [photobookOffersReviewImagesIndex, setPhotobookOffersReviewImagesIndex] = useState(0); 
        const [isLightboxOpen, setIsLightboxOpen] = useState({});
        const toggleLightboxOpen = () => {
            setIsLightboxOpen({...isLightboxOpen, [bookOffersReviewId]: !isLightboxOpen[bookOffersReviewId]});
        };

        const getLightboxImageTitle = (bookOffersReviewId) => {
            let dateObject = new Date(cardDetails[0].bookReviews[bookOffersReviewId].created_at); 
            let publishDate =  dateObject.toLocaleDateString("pl-PL", { 
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            // const help_ = (bookOffersReviewId) => {
            //     return (
            //         <>
            //             <Image src={cardDetails[0].bookReviews[bookOffersReviewId].kolomnieUser.image_url ? cardDetails[0].bookReviews[eventReviewId].kolomnieUser.image_url : IconPaths.navbarProfileIcon} alt={""} className={styles["cdd--sec-l-r-rp-ur-box-h-pd-pr-ico" /> */}
            //             <div className={styles["cdd--sec-l-r-rp-ur-box-h-pd-pr-t">
            //                 {cardDetails[0].bookReviews[bookOffersReviewId].kolomnieUser.first_name}
            //                 {' '}
            //                 {cardDetails[0].bookReviews[bookOffersReviewId].kolomnieUser.last_name}
            //             </div>
            //             {getTitle(cardDetails) + publishDate}
            //         </>
            //     )
            // }
            // return <div itemProp="description" dangerouslySetInnerHTML={{__html: help_(eventReviewId) }} />
            return (
                getTitle(cardDetails, locale)
                + ', '
                + cardDetails[0].bookReviews[eventReviewId].kolomnieUser.first_name 
                + ' '
                + cardDetails[0].bookReviews[eventReviewId].kolomnieUser.last_name
                + ', '
                + publishDate
            )
        }

        return (
            // cardDetails?.length > 0
            // && cardDetails[0].bookReviews
            // && cardDetails[0].bookReviews.length
            // && cardDetails[0].bookReviews[0].reviewImages
            // && cardDetails[0].bookReviews[0].reviewImages.length > 0 ?
            cardDetails?.[0]?.bookReviews?.[0]?.reviewImages?.length > 0 ?
                <div className={styles["cdd--sec-l-r-rp-ur-box-i"]}>
                    {/* <div className={styles["cdd--sec-l-r-rp-ur-box-i-arrow-l" onClick={() => divbookOffersReviewImagesRef.current.scrollLeft -= 150}>
                        <Image src={IconPaths.leftArrowIcon} alt={genAltText(IconPaths.leftArrowIcon)} className={styles["cdd--sec-l-r-rp-ur-box-i-arrow-ico" />
                    </div> */}
                    <div ref={divbookOffersReviewImagesRef} id="cdd--sec-l-r-rp-ur-box-i-sec"  className={styles["cdd--sec-l-r-rp-ur-box-i-sec"]}>
                        {
                            bookOffersReviewImages.filter(image => image.path).map((image, idx) => (
                                <Image
                                    src={image.path}
                                    alt={image.path}
                                    width={110} height={110}
                                    key={idx}
                                    className={styles['cdd--sec-l-r-rp-ur-box-i-img']}
                                    onClick={() => toggleLightboxOpen()}
                                    style={{ cursor: 'pointer' }}                                   
                                />
                            ))
                        }
                        {
                            isLightboxOpen[bookOffersReviewId] && (
                                <Lightbox
                                    mainSrc={bookOffersReviewImages[photobookOffersReviewImagesIndex].path}
                                    nextSrc={bookOffersReviewImages[(photobookOffersReviewImagesIndex + 1) % bookOffersReviewImages.length].path}
                                    prevSrc={bookOffersReviewImages[(photobookOffersReviewImagesIndex + bookOffersReviewImages.length - 1) % bookOffersReviewImages.length].path}
                                    onCloseRequest={() => setIsLightboxOpen(false)}
                                    onMovePrevRequest={() => setPhotobookOffersReviewImagesIndex((photobookOffersReviewImagesIndex + bookOffersReviewImages.length - 1) % bookOffersReviewImages.length)}
                                    onMoveNextRequest={() =>
                                        setPhotobookOffersReviewImagesIndex((photobookOffersReviewImagesIndex + 1) % bookOffersReviewImages.length)
                                    }
                                    imageTitle={getLightboxImageTitle(bookOffersReviewId)}
                                    imageCaption={BookOfferSourceIntl + ': ' + getSourceName(cardDetails[0].source)}
                                />
                            )
                        }
                    </div>
                    {/* <div className={styles["cdd--sec-l-r-rp-ur-box-i-arrow-r" onClick={() => divbookOffersReviewImagesRef.current.scrollLeft += 150}>
                        <Image src={IconPaths.rightArrowIcon} alt={""} className={styles["cdd--sec-l-r-rp-ur-box-i-arrow-ico" />
                    </div> */}
                </div>
            : ''
        )
    }

    const BookShortDescription = ({card}) => {
        return (
            card?.short_description ?
            <>
                <div className={styles["cdd--sec-l-sd"]}>
                    <div className={styles["cdd--sec-l-sd-h"]}>
                        <h2>
                            <FormattedMessage
                                id = "ShortDescription"
                                defaultMessage="Skrócony opis"
                            />
                        </h2>
                    </div>
                    <div className={styles["cdd--sec-l-sd-t"]} itemProp="description">
                        {getShortDescription(card, locale)}
                    </div>
                </div>
            </>
            : ''
        )
    }

    const BookStickyInfo = ({cardDetails}) => {  
        const [isLocationDetailsModalBoxOpen, setIsLocationDetailsModalBoxOpen] = useState(false);
        return (
            <>
                <div className={styles["cdd--sec-s-i-wrp"]}>
                    <FaInfoCircle 
                        className={styles["cdd--ico"]} 
                        data-tooltip-id="my-tooltip-name" 
                        data-tooltip-html={'Szczegóły lokalizacji'}
                        onClick={() => setIsLocationDetailsModalBoxOpen(true)}
                    />
                    {/* ReactTooltip */}
                    <ReactTooltip 
                        key={new Date() + 'my-tooltip-info'}
                        id="my-tooltip-info" 
                        place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                        className="custom-tooltip" 
                        effect="solid"
                    />
                    <div className={styles["cdd--sec-s-i-src"]}>
                        <Image
                            src={LogoPaths.searchBookLogo}
                            alt={genAltText(LogoPaths.searchBookLogo)}
                            width={324} height={50} 
                            className={styles["cdd--sec-s-i-src-img"]}
                        />
                    </div>
                </div>

                {/* Nazwa lokalizacji/ Wydawca */}
                <div className={styles["cdd--sec-s-i-t"]}>
                    <div className={`${styles['cdd--sec-s-i-t-inr']}`}>
                        <div className={styles["cdd--sec-s-i-t-dt"]}>
                            {/* Nazwa lokalizacji/ Wydawca */}
                            <div className={styles["cdd--sec-s-i-dt-h"]}>
                                <h3>
                                    <FormattedMessage
                                        id = "BookLocationName"
                                        defaultMessage="Nazwa lokalizacji"
                                    />
                                </h3>
                                <div>
                                    <span style={{marginRight: '-6px', color: '#40466a'}}>
                                        <FormattedMessage
                                            id = "Publisher"
                                            defaultMessage="Wydawca"
                                        />
                                    </span>
                                    {renderStatusIcon(cardDetails[0].location.is_publisher)}
                                </div> 
                            </div> 
                            <div className={styles["cdd--sec-s-i-dt-t"]} data-tooltip-id="my-tooltip-name" data-tooltip-html={getLocationName(cardDetails[0].location, 500)}>
                                <div className={styles["cdd--sec-d"]}>
                                    <FaStore className={styles["cdd--sec-d-i"]} />
                                    <div className={styles["cdd--sec-d-d"]}>
                                        {getLocationName(cardDetails[0].location, 30)}
                                    </div>
                                    {/* ReactTooltip */}
                                    <ReactTooltip 
                                        key={new Date() + 'my-tooltip-name'}
                                        id="my-tooltip-name" 
                                        place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                                        className="custom-tooltip" 
                                        effect="solid"
                                    />
                                </div> 
                            </div>

                            <BookStickyInfoMenu />
                            {/* Kontakt */}
                            {
                                bookStickyInfoMenu === '1' &&
                                <div className={styles["cdd--sticky-menu-sec"]}>
                                    {/* Adres */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaMapMarkerAlt className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                {getLocationAddress(cardDetails[0].location, 80)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Telefon */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaPhone className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                {getLocationPhone(cardDetails[0].location)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Email */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaEnvelope className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                {getLocationEmail(cardDetails[0].location)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* www */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaGlobe className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                {getLocationWww(cardDetails[0].location)}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            }
                            {/* Płatności */}
                            {
                                bookStickyInfoMenu === '2' &&
                                <div className={styles["cdd--sticky-menu-sec"]}>
                                    {/* Gotówką */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaMoneyBillWave className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "payment_cash_only" defaultMessage="payment_cash_only"/>
                                                 {renderStatusIcon(cardDetails[0].location.payment_cash_only)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Blik */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaMobileAlt className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "payment_blik" defaultMessage="payment_blik"/>
                                                 {renderStatusIcon(cardDetails[0].location.payment_blik)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Visa */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaCreditCard className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "payment_visa" defaultMessage="payment_visa"/>
                                                 {renderStatusIcon(cardDetails[0].location.payment_visa)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Mastercard */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaCreditCard className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "payment_mastercard" defaultMessage="payment_mastercard"/>
                                                 {renderStatusIcon(cardDetails[0].location.payment_mastercard)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* American Express */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaCreditCard className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "payment_amex" defaultMessage="payment_amex"/>
                                                 {renderStatusIcon(cardDetails[0].location.payment_amex)}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            }
                            {/* Parking */}
                            {
                                bookStickyInfoMenu === '3' &&
                                <div className={styles["cdd--sticky-menu-sec"]}>
                                    {/* Parking na ulicy */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaCar className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "parking_street" defaultMessage="parking_street"/>
                                                 {renderStatusIcon(cardDetails[0].location.parking_street)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Dedykowany parking */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaParking className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "parking_lot" defaultMessage="parking_lot"/>
                                                 {renderStatusIcon(cardDetails[0].location.parking_lot)}
                                            </div>
                                        </div> 
                                    </div>

                                    {/* Parking typu valet */}
                                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                                        <div className={styles["cdd--sec-d"]}>
                                            <FaKey className={styles["cdd--sec-d-i"]}/>
                                            <div className={styles["cdd--sec-d-d"]}>
                                                <FormattedMessage id = "parking_valet" defaultMessage="parking_valet"/>
                                                 {renderStatusIcon(cardDetails[0].location.parking_valet)}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <Image 
                        src={ImagePaths.rectangleStickyInfoImg} 
                        alt={genAltText(ImagePaths.rectangleStickyInfoImg)} 
                        width={282} height={3} 
                        className={styles["cdd--sec-s-i-rec"]} 
                    />
                    
                    {/* Godziny otwarcia */}
                    <div className={styles["cdd--sec-s-i-dt-t"]}>
                        <div className={styles["cdd--sec-d"]} onClick={() => setIsOpeningHoursModalBoxOpen(true)} style={{ cursor: 'pointer', color: '#40466a' }}>
                            <FaRegClock className={styles["cdd--sec-d-i"]}/>
                            <div className={styles["cdd--sec-d-d"]}>
                                <FormattedMessage
                                    id = "OpeningHours"
                                    defaultMessage="Godziny otwarcia"
                                />
                            </div>
                        </div> 
                    </div> 
                    {/* Opcje dostawy */}
                    <div className={styles["cdd--sec-s-i-dt-t"]} onClick={() => setIsDeliveryOptionsModalBoxOpen(true)} style={{ cursor: 'pointer', color: '#40466a' }}>
                        <div className={styles["cdd--sec-d"]}>
                            <FaTruck className={styles["cdd--sec-d-i"]}/>
                            <div className={styles["cdd--sec-d-d"]}>
                                <FormattedMessage
                                    id = "DeliveryOptions"
                                    defaultMessage="Opcje dostawy"
                                />
                            </div>
                        </div> 
                    </div>
                    {/* Jak dojechać */}
                    <div className={styles["cdd--sec-s-i-dt-t"]} onClick={() => setIsPublicTransitModalBoxOpen(true)} style={{ cursor: 'pointer', color: '#40466a' }}>
                        <div className={styles["cdd--sec-d"]}>
                            <MdDirectionsBus className={styles["cdd--sec-d-i"]}/>
                            <div className={styles["cdd--sec-d-d"]}>
                                <FormattedMessage
                                    id = "HowToGet"
                                    defaultMessage="Opcje dostawy"
                                />
                            </div>
                        </div> 
                    </div>
                    
                    <Image 
                        src={ImagePaths.rectangleStickyInfoImg} 
                        alt={genAltText(ImagePaths.rectangleStickyInfoImg)} 
                        width={282} height={3} 
                        className={styles["cdd--sec-s-i-rec"]} 
                    />

                    {/* Dodaj do ulubionych/ Usuń z ulubionych/Kupuję */}
                    <div className={styles["cdd--sec-s-i-t-btn"]}>
                        {/* Dodaj do ulubionych/ Usuń z ulubionych */}
                        { isFavorite(cardDetails[0].id) ? 
                            <button 
                                className={styles["cdd--sec-s-i-t-btn-remove-fav"]} 
                                onClick={()=> toggleFavorite(cardDetails[0].id)}
                            >
                                <span className={styles["cdd--sec-s-i-t-btn-remove-fav-t"]}> 
                                    <FormattedMessage
                                        id = "RemoveFromFavorites"
                                        defaultMessage="Usuń z ulubionych"
                                    />
                                </span>     
                            </button>
                            :
                            <button 
                                className={styles["cdd--sec-s-i-t-btn-add-fav"]} 
                                onClick={()=> toggleFavorite(cardDetails[0].id)}
                            >
                                <span className={styles["cdd--sec-s-i-t-btn-add-fav-t"]}> 
                                    <FormattedMessage
                                        id = "AddToFavorites"
                                        defaultMessage="Dodaj do ulubionych"
                                    />
                                </span>     
                            </button>
                        }
                        {/* Kupuję */}
                        {
                            !bookInformationAmazonAffiliateInfo ? 
                            <button 
                                className={styles["cdd--sec-s-i-t-btn-cal"]} 
                                onClick={()=> {handleBookOrderClick(cardDetails[0].location.www)}}
                                style={{opacity: cardDetails[0].location.www ? '1' : '0.3' }} 
                            >
                                <span className={styles["cdd--sec-s-i-t-btn-cal-t"]}> 
                                    <FormattedMessage
                                        id = "IBuy"
                                        defaultMessage="Kupuję"
                                    />
                                </span>     
                            </button>
                            : ''
                        }

                        {/* Linki zewnętrzne */}
                        <div className={styles["cdd--sec-s-i-t-btn-ext-links"]}>
                            <FormattedMessage
                                id = "ExternalLinks"
                                defaultMessage="Linki zewnętrzne"
                            />
                        </div>
                        {
                            cardDetails?.length > 0 ?
                                <div className={styles["cdd--sec-s-i-t-btn-sm"]}>
                                    {
                                        // cardDetails[0]?.location.facebook_link ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fb"]}>
                                                <a 
                                                    href={cardDetails[0].location.facebook_link || handleSocialMediaClick()} 
                                                    alt={'facebook'}  
                                                    target={cardDetails[0].location.facebook_link ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                    >
                                                    <Image 
                                                        src={IconPaths.facebookDetailsIcon} 
                                                        alt={genAltText(IconPaths.facebookDetailsIcon)} 
                                                        width={24} height={24} 
                                                        className={styles["cdd--sec-l-o-c-sec-c-item-c-fb"]} 
                                                        style={{opacity: cardDetails[0].location.facebookDetailsIcon ? '1' : '0.3' }} 
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.messenger ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.messenger || handleSocialMediaClick()} 
                                                    alt={'messenger'} 
                                                    target={cardDetails[0].location.messenger ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.facebookMessengerIcon} 
                                                        alt={genAltText(IconPaths.facebookMessengerIcon)} 
                                                        width={24} height={24}
                                                        style={{opacity: cardDetails[0].location.messenger ? '1' : '0.3' }} 
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.whatsapp ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.whatsapp || handleSocialMediaClick()} 
                                                    alt={'whatsapp'} 
                                                    target={cardDetails[0].location.whatsapp ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.whatsappIcon} 
                                                        alt={genAltText(IconPaths.whatsappIcon)} 
                                                        width={24} height={24} 
                                                        style={{opacity: cardDetails[0].location.whatsapp ? '1' : '0.3' }}
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.insta_url ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.insta_url || handleSocialMediaClick()} 
                                                    alt={'instagram'} 
                                                    target={cardDetails[0].location.insta_url ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.instagramIcon} 
                                                        alt={genAltText(IconPaths.instagramIcon)} 
                                                        width={24} height={24} 
                                                        style={{opacity: cardDetails[0].location.insta_url ? '1' : '0.3' }}
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.fsqr_url ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.fsqr_url || handleSocialMediaClick()} 
                                                    alt={'foursquare'} 
                                                    target={cardDetails[0].location.fsqr_url ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.foursquareIcon} 
                                                        alt={genAltText(IconPaths.foursquareIcon)} 
                                                        width={24} height={24} 
                                                        style={{opacity: cardDetails[0].location.fsqr_url ? '1' : '0.3' }}
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.yelp_url ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.yelp_url || handleSocialMediaClick()} 
                                                    alt={'yelp'} 
                                                    target={cardDetails[0].location.yelp_url ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.yelpIcon} 
                                                        alt={genAltText(IconPaths.yelpIcon)} 
                                                        width={24} height={24} 
                                                        style={{opacity: cardDetails[0].location.yelp_url ? '1' : '0.3' }}
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.tiktok_url ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.tiktok_url || handleSocialMediaClick()} 
                                                    alt={'tiktok'} 
                                                    target={cardDetails[0].location.tiktok_url ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.tiktokIcon} 
                                                        alt={genAltText(IconPaths.tiktokIcon)} 
                                                        width={24} height={24} 
                                                        style={{opacity: cardDetails[0].location.tiktok_url ? '1' : '0.3' }}
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                    {   
                                        // cardDetails[0]?.location.glovo_url ?
                                            <div className={styles["cdd--sec-s-i-t-btn-sm-fbmw"]}>
                                                <a 
                                                    href={cardDetails[0].location.glovo_url || handleSocialMediaClick()} 
                                                    alt={'glovo'} 
                                                    target={cardDetails[0].location.glovo_url ? "_blank" : ""}
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image 
                                                        src={IconPaths.glovoIcon} 
                                                        alt={genAltText(IconPaths.glovoIcon)} 
                                                        width={24} height={24}
                                                        style={{opacity: cardDetails[0].location.glovo_url ? '1' : '0.3' }} 
                                                    />
                                                </a>
                                            </div>
                                        // : ''
                                    }
                                </div>
                            : ''
                        }
                    </div>
                </div>

                <div className={styles["cdd--sec-s-i-brk"]} />
                
                {/* Cena/ Zamawiam/ Tagi */}
                <div className={styles["cdd--sec-s-i-b"]}>
                    {/* Cena */}
                    <div className={styles["cdd--sec-s-i-b-p"]}>
                        <h2>
                            <span>
                                <span style={{fontSize: "14px", fontWeight: "400"}}>
                                    <FormattedMessage
                                        id = "BookProceDetails"
                                        defaultMessage="Cena"
                                    />
                                </span>
                                <span content={getLowestPricevariants(cardDetails[0])}>
                                    {' ' +  getLowestPricevariants(cardDetails[0])}
                                </span>
                            </span>
                        </h2>
                    </div>

                    {/* Zamawiam */}
                    {
                        bookInformationAmazonAffiliateInfo ? 
                        <button 
                            className={styles["cdd--sec-s-i-b-btn"]} 
                            onClick={()=> {handleBookOrderClick(bookInformationAmazonAffiliateInfo.item_url)}}
                            style={{opacity: bookInformationAmazonAffiliateInfo.item_url ? '1' : '0.3' }} 
                        >
                            <div className={styles["cdd--sec-s-i-b-btn-f"]}>
                                <Image 
                                    src={IconPaths.stickyInfoBtnIcon} 
                                    alt={genAltText(IconPaths.stickyInfoBtnIcon)} 
                                    width={24} height={24} 
                                    className={styles["cdd--sec-s-i-b-btn-f-ico"]} 
                                />
                            </div>
                            <div className={styles["cdd--sec-s-i-b-btn-t"]}>
                                <FormattedMessage 
                                    // id = "BookOrder" defaultMessage="Zamawiam" 
                                    id = "OrderFromAmazon"
                                    defaultMessage="Zamawiam z Amazon" 
                                />
                            </div>
                        </button>
                        : ''
                    }


                    {/* Tagi */}
                    <div style={{marginTop: '14px', marginBottom: '-14px'}}>
                        {getTagCategoryList(cardDetails[0].location.tags, 'brak tagów')}
                    </div>
                </div>
                
                <OpeningHoursModalBox
                    modalTitle="Godziny otwarcia"
                    location={cardDetails[0].location}
                    openModal={isOpeningHoursModalBoxOpen}
                    setOpenModal={setIsOpeningHoursModalBoxOpen}
                />
                <DeliveryOptionsModalBox
                    modalTitle="Opcje dostawy"
                    location={cardDetails[0].location}
                    openModal={isDeliveryOptionsModalBoxOpen}
                    setOpenModal={setIsDeliveryOptionsModalBoxOpen}
                />
                <PublicTransitModalBox
                    modalTitle="Jak dojechać"
                    location={cardDetails[0].location}
                    openModal={isPublicTransitModalBoxOpen}
                    setOpenModal={setIsPublicTransitModalBoxOpen}
                />
                <LocationDetailsModalBox
                    modalTitle="Szczegóły lokalizacji"
                    location={cardDetails[0].location}
                    openModal={isLocationDetailsModalBoxOpen}
                    setOpenModal={setIsLocationDetailsModalBoxOpen}
                />
            </>
        );
    };

    const BookCategories = ({card}) => {
        return (
            card?.length > 0 && 
            card[0]?.category ?
                <div className={styles["cdd--sec-l-t"]}>
                    <div className={styles["cdd--sec-l-t-h"]}>
                        <h2>
                            <FormattedMessage
                                id = "Categories"
                                defaultMessage="Kategorie"
                            />
                        </h2>
                    </div>
                    <div className={styles["cdd--sec-l-t-l"]}>
                        {getTagCategoryList(cardDetails[0].category, 'brak kategorii')}
                    </div>
                </div>
            : ''
        )
    }
    // 
    // END Components Section
    // 
    // 

    // useEffect Section
    // 
    useEffect(() => { 
        // Jeżeli mamy dane przekazane jako props, ustawiamy je bezpośrednio
        if (bookDetailsData) {
            // setCardDetails(bookDetailsData);
            setShowSpinner(false); // Zakładam, że spinner powinien być ukryty po załadowaniu danych
        } else {
            // Jeśli nie ma danych przekazanych jako props, wtedy użyjemy Reduxa do ich pobrania
            let url = window.location.href;                       
            let book_offers_id = url.slice(url.lastIndexOf('/') + 1); 
            dispatch(fetchBookOffersById(book_offers_id));
        }

        // window.scrollTo({ top: 0, behavior: "smooth" }); // scroll to the top of the browser window when changing route
    }, [bookDetailsData, cardDetails, dispatch]);

    useEffect(() => {
        // Ustaw stan tylko jeśli dane z Reduxa uległy zmianie
        if (!bookOffersByIdIsLoading && bookOffersByIdData.length > 0) {
            debugLog('CardDetails bookOffersByIdData', 'debug', bookOffersByIdData);
            setCardDetails(bookOffersByIdData);
            setShowSpinner(false); // Zakładam, że spinner powinien być ukryty po załadowaniu danych
        }
    }, [bookOffersByIdData, bookOffersByIdIsLoading])

    useEffect(() => {
        setSimilarBookOffersOverview(similarBookOffersData);
    }, [similarBookOffersData, similarBookOffersIsLoading]);

    useEffect(() => {
        // Resetting the Similar Activities array near you
        setSimilarBookOffersOverview([]);

        if (cardDetails[0]?.location ) {
            getLatitudeLongitude(cardDetails[0].location)
        }

        if (bookDetailsData?.length > 0) {
            debugLog('Dashboard recentlyViewedBookssData bookDetailsData[0]', 'debug', bookDetailsData[0]);
            dispatch(addOrUpdateRecentlyViewdBookOffers(bookDetailsData[0]));
        }
    }, [cardDetails, bookDetailsData, getLatitudeLongitude, dispatch])

    useEffect(() => {                
        let latitude = latitudeLongitude.center.lat; 
        let longitude = latitudeLongitude.center.lng;
        let distance = 20;
        let category = 'Romanse';
        let page = 1;
        let url = window.location.href;                       
        let book_offers_id = url.slice(url.lastIndexOf('/') + 1); 
        dispatch(fetchSimilarBookOffers(latitude, longitude, distance, category, book_offers_id, page));
    }, [latitudeLongitude, dispatch, cookies]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSpinner(false);
            setShowSpinnerMessage(true);
        }, 5000);

        return () => clearTimeout(timer); // Czyszczenie timera, jeśli komponent zostanie odmontowany.
    }, []);

    const router = useNextRouter();
    const currentLocation = router.asPath;
    const prevLocationHref = usePrevious(currentLocation);
    // Zmiana ścieżki URL w zależności od języka
    useEffect(() => {
        if (userSelectedLanguage) {
            if ( prevLocationHref != undefined && prevLocationHref === router.asPath) {
                // window.location.reload();
                const cardDetailsUrl = createSitemapUrl(bookDetailsData[0], locale);

                // Sprawdź, czy aktualna domena zawiera subdomenę
                const currentSubdomain = currentHostname.split('.')[0];
                
                // Utwórz cardDetailsUrlWithDomain przed warunkiem
                let cardDetailsUrlWithDomain = new URL(cardDetailsUrl, originDomain);

                changeUrlWithoutReloading(cardDetailsUrlWithDomain);

                debugLog('CardDetails changeUrlWithoutReloading', 'debug', cardDetailsUrlWithDomain);
            }
        }
    }, [currentLocation, prevLocationHref, router, locale, bookDetailsData, currentHostname, originDomain, userSelectedLanguage, getLatitudeLongitude]);

    // Używany do weryfikacji, czy obraz dostępny jest pod danym URL-em i ustawia odpowiedni obraz (zastępczy lub właściwy), w zależności od wyniku tej weryfikacji
    useEffect(() => {
        const imageUrl = cardDetails[0].picture_url || ImagePaths.openBookLargeImg;
        fetch(`${imageUrl}?v=${new Date().getTime()}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Seting Cover Image
                if (cardDetails?.length > 0 ) {
                    setCoverImage(imageUrl); // Ustawienie URL obrazu, jeśli dostępny
                }
            })
            .catch(() => {
                setCoverImage(ImagePaths.openBookLargeImg); // Ustawienie obrazu zastępczego w przypadku błędu
            });
    }, [cardDetails]);

    // Ładowanie informacji o książce z `book_information` na podstawie ISBN w celu uzyskania danych z `amazon_affiliate`
    useEffect(() => {
        const fetchBookInformationData = async () => {
            let details;
            if (cardDetails[0].isbn) {
                const isbn = cardDetails[0].isbn;
                details = await extractBookInformationDetails({isbn});
            }
            if (details && details.items && details.items.length > 0 && details.items[0].amazonAffiliates && details.items[0].amazonAffiliates.length > 0) {
                setbookInformationAmazonAffiliateInfo(details.items[0].amazonAffiliates[0]);
            }              
        };
        fetchBookInformationData();
    }, [cardDetails]);

    // debugLog
    useEffect(() => {
        // window.location.replace(window.location.href.replace(/#/g, ''));
        debugLog('Dashboard recentlyViewedBookssData', 'debug', recentlyViewedBookOffersData);
    }, [recentlyViewedBookOffersData]);
    // 
    // END useEffect Section
    // 

    // Zaktualizuj breadcrumbs przy montowaniu komponentu
    useEffect(() => {
        const getCategoryName = (data) => {
            if (data.category) {
                // Rozdziela kategorie jeśli są oddzielone przecinkami i zwraca pierwszą z nich
                return data.category.split(',')[0].trim();
            } else {
                return 'Inne';
            }
        };        

        const getFirstPathSegmentAfterDomain = () => {
            const pathSegments = window.location.pathname.split('/').filter(segment => segment.trim() !== '');
            // Zmiana języka na podstawie ścieżki
            let translatedCategory;
            if (validPaths.hasOwnProperty(pathSegments[0])) {
                translatedCategory = validPaths[pathSegments[0]].translation;
            }
            debugLog('getFirstPathSegmentAfterDomain', 'debug', pathSegments);
            return translatedCategory || process.env.NEXT_PUBLIC_WEBPAGE_DOMAIN_NAME;
        }

        const getTitleForBreadcrumb = (data, lang) => {
            const defaultTitle = data.title || '';
            // if (!lang || !title) return defaultTitle;
            if (!data.title['title_' + lang]) return defaultTitle;
            // return title['title_' + lang] || defaultTitle;
        }
    
        const getTagName = (data, lang) => {
            const defaultTags = {
                en: "Other",
                de: "Andere",
                pl: "Inne",
                uk: "Інший",
            };
        
            if (Array.isArray(data) && data.length > 0 && typeof data[0].tag_name === 'string') {
                return data[0].tag_name;
            }
        
            return defaultTags[lang];
        };  

        const fullPath = window.location.origin + router.asPath;

        setBreadcrumbs([
            { title: 'Home', href: '/' },
            { 
                title:
                        cardDetails?.length > 0 && cardDetails[0]?.category
                        ?
                            getCategoryName(cardDetails[0])
                        :
                            cardDetails?.length > 0 && cardDetails[0]?.location.tags 
                            ?
                                getTagName(cardDetails[0].location.tags, locale)
                            :
                            getFirstPathSegmentAfterDomain()
                    ,
                // href: window.location.pathname
                href: fullPath
            },
            { title: getTitleForBreadcrumb(cardDetails[0], locale), href: '/' },
        ]);
        // To będzie wywołane tylko raz, gdy komponent zostanie zamontowany

        // Opcjonalnie zwróć funkcję czyszczącą, jeśli chcesz zresetować breadcrumbs
        // gdy komponent będzie odmontowywany
        return () => {
            setBreadcrumbs([]);
        };
    }, [setBreadcrumbs, cardDetails, locale, router.asPath]);

    const fullUrl = `${window.location.origin}${currentLocation}`;
    const relativeImagePath = coverImage;
    const fullImagePath = `${process.env.NEXT_PUBLIC_APP_URL}${relativeImagePath}`;

    // 
    // application/ld+json
    // 
    const bookSchema = {
        "@context": "https://schema.org",
        "@type": "Book",
        "name": getTitle(cardDetails, locale),
        "author": {
            "@type": "Person",
            "name": getBookAuthors(cardDetails[0])
        },
        "isbn": getISBN(cardDetails[0]),
        "bookEdition": cardDetails[0]?.issue_number ? cardDetails[0].issue_number : '',
        "bookFormat": "https://schema.org/Hardcover", // lub Paperback, EBook, itd.
        "publisher": {
            "@type": "Organization",
            "name": getPublisher(cardDetails[0])
        },
        "datePublished": getYear(cardDetails[0]),
        "image": fullImagePath,
        "description": cardDetails?.description ? cardDetails[0].description : '',
        "url": fullUrl,
        // Tutaj można dodać inne odpowiednie informacje, takie jak liczba stron, język, oceny itp.
    };
    const jsonString = serializeToJson(bookSchema);
    // 
    // END application/ld+json
    // 

    return (
        <>
            <Breadcrumbs /> 
            {/* <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: jsonString }}
                />
            </Head> */}
            { 
                cardDetails?.length > 0
                ?   
                    <>
                        <div className={styles["cdd--sec"]}>
                            <div className={styles["cdd--sec-l"]}>
                                {
                                    cardDetails?.length > 0
                                    && coverImage ?
                                        <>
                                            <div className={styles["cdd--sec-l-i"]} onClick={() => toggleLightboxOpen()} >
                                                {/* BookEditRemoveBtn */}
                                                { 
                                                    sessionData && sessionData.id === cardDetails[0].users_id 
                                                    && <BookEditRemoveBtn card={cardDetails[0]}/> 
                                                }
                                                { 
                                                    typeof coverImage === 'string' ?
                                                        <ResizableImage
                                                            imageUrl={coverImage} 
                                                            width="80%"
                                                            imageAlt={cardDetails.title}
                                                            // height="209.5px"
                                                            // cardDetails[0].title.substring(0, 10).concat("...")
                                                            placeholderSrc={ImagePaths.bookLargeBlurImg}
                                                            borderTopLeftRadius={'14px'}
                                                            borderTopRightRadius={'14px'}
                                                            borderBottomLeftRadius={'14px'}
                                                            borderBottomRightRadius={'14px'}
                                                        />: ''
                                                }
                                                {/* BookAmazonAffiliate */}
                                                {
                                                    bookInformationAmazonAffiliateInfo
                                                    && <BookAmazonAffiliate amazonAffiliateInfo={bookInformationAmazonAffiliateInfo}/>
                                                }
                                            </div>
                                            {/* {
                                                isLightboxOpen && (
                                                    <Lightbox
                                                        mainSrc={cardDetails[0].bookOffersImages[photoCardDetailsIndex].highlighted}
                                                        nextSrc={cardDetails[0].bookOffersImages[(photoCardDetailsIndex + 1) % cardDetails[0].bookOffersImages.length].highlighted}
                                                        prevSrc={cardDetails[0].bookOffersImages[(photoCardDetailsIndex + cardDetails[0].bookOffersImages.length - 1) % cardDetails[0].bookOffersImages.length].highlighted}
                                                        onCloseRequest={() => setIsLightboxOpen(false)}
                                                        onMovePrevRequest={() => setPhotoCardDetailsIndex((photoCardDetailsIndex + cardDetails[0].bookOffersImages.length - 1) % cardDetails[0].bookOffersImages.length)}
                                                        onMoveNextRequest={() =>
                                                            setPhotoCardDetailsIndex((photoCardDetailsIndex + 1) % cardDetails[0].bookOffersImages.length)
                                                        }
                                                        imageTitle={getTitle(cardDetails, locale)}
                                                        imageCaption={BookOfferSourceIntl + ': ' + getSourceName(cardDetails[0].source)}
                                                    />
                                                )
                                            } */}
                                        </>
                                    : ''
                                }
                                {/* BookImagesBelt */}
                                {/* <BookImagesBelt bookOffersImages={cardDetails[0].bookOffersImages}/> */}

                                {/* BookBasicInfo */}
                                <BookBasicInfo cardDetails={cardDetails} />
                                
                                {/* BookMobileMenu */}
                                <BookMobileMenu />
                                
                                {/* BookStickyInfo */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '1' ? 
                                        <div className={styles["cdd--sec-s-i-m"]}>
                                            <BookStickyInfo cardDetails={cardDetails} />
                                        </div>
                                    : ''
                                }

                                {/* BookDescription */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '1' ? 
                                        <BookDescription card={cardDetails[0]} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookDescription card={cardDetails[0]} />
                                    : ''
                                }

                                {/* BookAdditionalInfo */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '1' ? 
                                        <BookAdditionalInfo card={cardDetails[0]} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookAdditionalInfo card={cardDetails[0]} />
                                    : ''
                                }

                                {/* BookShortDescription */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '1' ? 
                                            <BookShortDescription  card={cardDetails[0]} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookShortDescription  card={cardDetails[0]} />
                                    : ''
                                }

                                {/* BookReviews */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '2' ? 
                                        <BookReviews cardDetails={cardDetails} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookReviews cardDetails={cardDetails} />
                                    : ''
                                }

                                {/* BookOtherData */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '3' ? 
                                        <BookOtherData location={cardDetails[0].location} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookOtherData location={cardDetails[0].location} />
                                    : ''
                                }

                                {/* Stan książki */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '3' ? 
                                        <BookCondition card={cardDetails[0]} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookCondition card={cardDetails[0]} />
                                    : ''
                                }

                                {/* BookCategories */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '3' ? 
                                        <BookCategories card={cardDetails} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <BookCategories card={cardDetails} />
                                    : ''
                                }
                                {/* LocationMap */}
                                { 
                                    window.innerWidth < 767 && bookDetailsMobileMenu === '1' ? 
                                        <LocationMap userCoordinates={coordinates} latitudeLongitude={latitudeLongitude} location={cardDetails[0].location} btnMarginTop={'-34px'} />
                                    : 
                                    window.innerWidth >= 767 ?
                                        <LocationMap userCoordinates={coordinates} latitudeLongitude={latitudeLongitude} location={cardDetails[0].location} />
                                    : ''
                                }
                            </div>

                            {/* BookStickyInfo */}
                            <div className={styles["cdd--sec-s-i"]} >
                                <BookStickyInfo cardDetails={cardDetails} />
                            </div>
                        </div>
                        {/* Podobne niedaleko Ciebie */}
                        { 
                            similarBookOffersOverview?.length > 0 ?
                                <div className={styles["cdd--sec-snm"]}>
                                    <div className={styles["cdd--sec-snm-h"]}>
                                        <h1 onClick={()=> changeIsShownState(true)} style={{ cursor: 'pointer' }}>
                                            <FormattedMessage
                                                id = "SimilarNearYou"
                                                defaultMessage="Podobne niedaleko Ciebie"
                                            />
                                        </h1>
                                        { 
                                            <CardsGrid 
                                                cardsGridData={similarBookOffersOverview} 
                                                dataReducer={'similarBookOffers'} 
                                                searchPhrase={searchPhrase}
                                                sortFieldAndDirection={sortState} // Przekazanie stanu sortState
                                            />
                                        }
                                    </div>
                                </div>  
                            : 
                                <div className={styles["cdd--sec-snm"]}>
                                    <div className={styles["cdd--sec-snm-h"]}>
                                        <h1 onClick={()=> changeIsShownState(true)} style={{ cursor: 'pointer' }}>
                                            <FormattedMessage
                                                id = "SimilarNearYou"
                                                defaultMessage="Podobne niedaleko Ciebie"
                                            />
                                        </h1>
                                        <NoResultsContent 
                                            resultImage={ImagePaths.noOffersImg} 
                                            resultHeader={'NoSimilarItems'} 
                                            resultText={'DiscoverOtherUniqueItems'} 
                                            resultButtonLable={'HomePage'}
                                            resultHandleButtonClick={handleReturnHomePageClick}
                                        />
                                    </div>
                                </div>                          
                        }
                    </>
                :
                    <div className={styles["cdd--spn-cnt"]}>
                        {showSpinner && (
                            <Spinner animation="border" variant="secondary" role="status">
                                <span className="visually-hidden">
                                    {Trans('Loading')}
                                </span>
                            </Spinner>
                        )}

                        {showSpinnerMessage && (
                            <div>
                                <p className={styles["cdd--page-not-exist"]}>
                                    <FormattedMessage
                                        id = "PageNotExistOrIsDamaged"
                                        defaultMessage="Szukana strona nie istnieje lub jest uszkodzona...!"
                                    />
                                </p>
                                <Link href="/" className={styles["cdd--btn"]}>
                                    <FormattedMessage
                                        id = "BackToMainPage"
                                        defaultMessage="Wróć na główną stronę"
                                    />
                                </Link>
                            </div>
                        )}
                    </div>
            }
            <AddBookFooter />
            <DeleteConfirmationDialog
                open={openDeleteConfirmationDialog}
                handleClose={handleCloseDeleteConfirmationDialog}
                handleConfirm={handleConfirmDeleteConfirmationDialog}
            />
            <Loading isLoading={loading} isLoadingText={Trans('Processing')}/>
        </> 
    )
}

// PropTypes
CardDetails.propTypes = {
    bookOffersByIdData: PropTypes.array,
    bookOffersImages: PropTypes.array,
    bookUrlPathData: PropTypes.object,
    bookDetailsData: PropTypes.array,
    card: PropTypes.object,
    cardDetails: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          users_id: PropTypes.number.isRequired,
          locations_id: PropTypes.number.isRequired,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          original_title: PropTypes.string,
          collection_title: PropTypes.string,
          authors: PropTypes.string,
          publisher: PropTypes.string,
          year: PropTypes.string,
          page_count: PropTypes.string,
          format: PropTypes.string,
          weight: PropTypes.string,
          cover_type: PropTypes.string,
          description: PropTypes.string,
          short_description: PropTypes.string,
          published_city: PropTypes.string,
          isbn: PropTypes.string,
          ean: PropTypes.string,
          issn: PropTypes.string,
          price: PropTypes.number.isRequired,
          picture_url: PropTypes.string,
          picture_source: PropTypes.string,
          languages: PropTypes.string,
          issue_number: PropTypes.string,
          category: PropTypes.string,
          condition: PropTypes.string,
          condition_description: PropTypes.string,
          source: PropTypes.string,
          created_at: PropTypes.instanceOf(Date),
          updated_at: PropTypes.instanceOf(Date),
          bookOffersImages: PropTypes.array,
          bookReviews: PropTypes.array,
          location: PropTypes.array,
        })
    ),
    userComments: PropTypes.array,
    bookOffersReviewImages: PropTypes.array,
    bookOffersReviewId: PropTypes.number,
    amazonAffiliateInfo: PropTypes.array,
};

export default CardDetails;
