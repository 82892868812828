// src/components/_common/PageNotFound/index.js
import React from "react"
import Image from 'next/image';

import styles from './css/page-not-found.module.css'

import { useWindowDimensions } from '../../../utils';
import { ImagePaths } from '../../../constants';
import { genAltText } from '../../../utils';

import debugLog from '@/helpers/debugLog'; 

const PageNotFound = () => {
  const { height, width } = useWindowDimensions();
  debugLog('PageNotFound height width', 'info', height + "|" + width);
  return (
      <div className={styles["pnf--con"]}>
        <div className={styles["pnf--sec"]}>
          <Image src={ImagePaths.error404} alt={genAltText(ImagePaths.error404)} width={800} height={600} className={styles["img-p-n-f"]} />
        </div>
      </div>
  );
}

export default PageNotFound;