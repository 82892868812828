// src/lib/redux/bookOffersById/ApiAction.js
import ACTION_TYPES from './ActionTypes';

export const fetchData = () => ({
  type: ACTION_TYPES.BOOK_OFFERS_BY_ID_PENDING,
});

export const fetchSuccess = (data) => ({
  type: ACTION_TYPES.BOOK_OFFERS_BY_ID_SUCCESS,
  payload: data,
});

export const fetchError = (error) => ({
  type: ACTION_TYPES.BOOK_OFFERS_BY_ID_ERROR,
  payload: error,
});